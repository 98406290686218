import { route } from '../store/hydrate';
import { STATUS_LOADING, STATUS_SUCCESS, PATH } from '../constants';

route(PATH.discountCart, async ({ store }) => {
  const {
    dispatch: { page, rule, global }
  } = store;
  const {profile: {user: {id}}} = store.getState()
  page.setStatus(STATUS_LOADING);
  try {
    const query = id === 1 ? '' : `user.id=${id}`;
    await Promise.all([global.getStorage(), rule.fetchCartRule({query})])
    // await  global.getStorage()
    // await rule.fetchCartRule({query})
    page.setStatus(STATUS_SUCCESS);
  } catch (error) {
    page.setErrors(error);
  }
});

import { object, string, ref, number } from 'yup';

export const registerSchema = object().shape({
  username: string()
    .required('This field is required.'),
  email: string()
    .required('This field is required.')
    .email('email format is not right'),
  password: string()
    .min(8, 'The length is less than 8')
    .required('This field is required.'),
  passwordConfirm: string()
    .required('This field is required.')
    .oneOf([ref('password')], 'Passwords must match')
});
export const userDetailSchema = object().shape({
  username: string()
    .required('This field is required.'),
  email: string()
    .required('This field is required.')
    .email('email format is not right')
});
export const loginSchema = object().shape({
  username: string()
    .required('User name is required.'),
  password: string()
    .min(8, 'The length is less than 8')
    .required('Password is required.'),
});

export const catalogRuleSchema = object().shape({
  name: string()
    .required('This field is required.'),
  discount_amount: number().required('This field is required.').min(0, 'The amount is less than 0')
});


export const STATUS_INITIAL = -1000;
export const STATUS_LOADING = -500;
export const STATUS_SUCCESS = 200;
export const STATUS_ERROR = 500;

export const ENV_LOCAL = 'local';
export const ENV_PROD = 'production';

export const BASE_URL = 'https://shpoap-admin.gpbatteries.com'
export const PATH = {
  home: '/',
  about: '/about',
  discount: '/discounts',
  discountCart: '/cartdiscounts',
  user: '/user',
  code: '/code',
  userDetail: '/user/:slug',
  discountDetail: '/discount/:slug',
  discountCartDetail: '/cartdiscounts/:slug',
  discountCreate: '/discount/catalog/new',
  discountCreateCart: '/discount/cart/new',
  codeDetail: '/code/:slug',
  business: '/business/:slug',
  login: '/login',
  logout: '/logout',
  register: '/register'
};

export const ROLE = {
  admin: 'administrator'
}

export const STOREFRONT_ACCESS_TOKEN = '54572ccd64896764956f50bba6b7b1a4'

export const STORE_NAME = 'https://francfranc-develope.myshopify.com/'

export const PLACEHOLDER_IMAGE = 'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=webp&v=1530129081'

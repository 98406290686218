import { route } from '../store/hydrate';
import { STATUS_LOADING, STATUS_SUCCESS, PATH } from '../constants';

route(PATH.discount, async ({ store }) => {
  const {
    dispatch: { page, rule }
  } = store;
  const {profile: {user: {id}}} = store.getState()
  page.setStatus(STATUS_LOADING);
  try {
    const query = `user.id=${id}`
    await rule.fetchCatalogRule({query})
    page.setStatus(STATUS_SUCCESS);
  } catch (error) {
    page.setErrors(error);
  }
});

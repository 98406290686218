import React from 'react';
import {Frame, Loading} from '@shopify/polaris';

const CustomLoading = () => (
  <div style={{height: '100px'}}>
    <Frame>
      <Loading />
    </Frame>
  </div>
);

export default CustomLoading;

import React from 'react';
// import ForgetPassword from '../../components/Modal/ForgetPassword';
// import Success from '../..components/Modal/Success';
// import Error from '../..components/Modal/Error';
import Loading from '../../components/Loading';

const modal = {
  state: {
    component: '',
    isPopup: false,
    isLoading: false,
    timeOut: true,
  },
  reducers: {
    setPopup(state, component) {
      return {
        ...state,
        component,
        isPopup: true,
        isLoading: false,
      };
    },
    setLoading(state, component) {
      return {
        ...state,
        component,
        isPopup: false,
        isLoading: true,
      };
    },
    close(state) {
      clearTimeout(state.timeOut);
      return {
        ...state,
        isPopup: false,
        isLoading: false,
      };
    },
    setTime(state, timeOut) {
      return {
        ...state,
        timeOut
      };
    },
  },
  effects: (dispatch) => ({
    async showSuccessPopupWithTime({ delay = 3000, text = '' } = {}) {
      dispatch.modal.setPopup(<Loading />);
      const timeOut = setTimeout(() => {
        dispatch.modal.close();
      }, delay);
      dispatch.modal.setTime(timeOut);
    },
    openForgetPassword() {
      dispatch.modal.setPopup(<Loading />);
    },
    loading() {
      dispatch.modal.setLoading(<Loading />);
    },
    showError(error) {
      dispatch.modal.setPopup(<Loading />);
    },
  }),
};

export default modal;

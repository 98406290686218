import { route } from '../store/hydrate';
import { STATUS_LOADING, STATUS_SUCCESS, PATH } from '../constants';
route(PATH.userDetail, async ({ store, route: { params }  }) => {
  const {
    dispatch: { page, global, profile }
  } = store;
  page.setStatus(STATUS_LOADING);
  try {
    await Promise.all([profile.fetchUser(params.slug), global.getStorage()])
    page.setStatus(STATUS_SUCCESS);
  } catch (error) {
    page.setErrors(error);
  }
  page.setStatus(STATUS_SUCCESS);
});

import { route } from '../store/hydrate';
import { STATUS_LOADING, STATUS_SUCCESS, PATH } from '../constants';
route(PATH.login, async ({ store }) => {
  const {
    dispatch: { page }
  } = store;
  page.setStatus(STATUS_LOADING);
  try {
    page.setStatus(STATUS_SUCCESS);
  } catch (error) {
    page.setErrors(error);
  }
});

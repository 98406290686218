import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { loginSchema } from "../../../utils/formSchema";
import { PATH } from "../../../constants.js";
import FieldComp from '../../Form/Field'
import {
  Layout,
  Page,
  Card,
  Button,
  FormLayout,
  InlineError
} from "@shopify/polaris";

const Login = ({ profileAction, modalAction }) => {
  const history = useHistory();
  const [loginError, setLoginError] = useState("");
  const initialLoginValues = {
    username: "",
    password: "",
  };

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <div className="card-login">
            <Card sectioned title="Login">
              
                <Formik
                  validationSchema={loginSchema}
                  initialValues={initialLoginValues}
                  onSubmit={async (values, actions) => {
                    const error = await profileAction.login(values);
                    if (error) {
                      setLoginError(error);
                    } else {
                      history.push(PATH.home);
                    }
                    actions.setSubmitting(false);
                  }}
                >
                  {({ isValid, isSubmitting }) => (
                    <Form>
                      <FormLayout>
                      {loginError &&
                        <InlineError message={loginError} />
                      }
                      <FieldComp
                        labelText="Username"
                        name="username"
                      />
                      <FieldComp
                        labelText="Password"
                        name="password"
                        type="password"
                      />
                      <div className="actions">
                      <Button primary submit disabled={!isValid || isSubmitting} loading={isSubmitting}>Log In</Button>
                      </div>
                      </FormLayout>
                    </Form>
                  )}
                </Formik>
            </Card>
          </div>
        </Layout.Section>
      </Layout>
    </Page>
  );
};
Login.propTypes = {
  profileAction: PropTypes.object,
  modalAction: PropTypes.object,
};

const mapStateToProps = ({ profile }) => ({ profile });
const mapActionToProps = ({ profile, modal }) => ({
  profileAction: profile,
  modalAction: modal,
});
export default connect(mapStateToProps, mapActionToProps)(Login);

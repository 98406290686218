import axios from 'axios';
import {BASE_URL} from '../constants'
export const configFromState = (state) => {
  const headers = {
  };
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  const backendUrl = BASE_URL
  
  return {
    headers,
    loggedIn: Boolean(accessToken),
    accessToken,
    backendUrl
  };
};

export const get = (url, config) => {
  return axios.get(url, config).then(response => response.data);
};

export const post = (url, data, config) => {
  return axios.post(url, data, config).then(response => response.data);
};

// can't use delete, its reserved words
export const deleteReq = (url, config) => {
  return axios.delete(url, config).then(response => response.data);
};

export const put = (url, data, config) => {
  return axios.put(url, data, config).then(response => response.data);
};

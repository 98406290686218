import { route } from '../store/hydrate';
import { STATUS_LOADING, STATUS_SUCCESS, PATH } from '../constants';

route(PATH.discountCartDetail, async ({ store, route: { params } }) => {
  const {
    dispatch: { page, rule, global }
  } = store;
  
  page.setStatus(STATUS_LOADING);
  try {
    await  global.getStorage()
    await rule.fetchCartRuleEdit({query:`id=${params.slug}`})
    page.setStatus(STATUS_SUCCESS);
  } catch (error) {
    page.setErrors(error);
  }
});

import { get, post } from '../../utils/http';
import {BASE_URL} from '../../constants'
import Client from 'graphql-js-client';
import typeBundle from '../../types';
import {extractError} from '../../utils/helpers'
const global = {
  state: {
    isMobile: false,
    storage: [],
    storeConfig: {},
    clients: [],
    collections: [],
    products: [],
    node_products: []
  },
  reducers: {
    setState(state, payload) {
      return {
        ...state,
        ...payload
      };
    },
    setStorage(state, payload) {
      return {
        ...state,
        storage: payload
      }
    },
    setClient(state, payload) {
      return {
        ...state,
        clients: payload
      }
    },
    setProducts(state, payload) {
      return {
        ...state,
        products: payload
      }
    },
    setCollections(state, payload) {
      return {
        ...state,
        collections: payload
      }
    },
    setNodeProducts(state, payload) {
      return {
      ...state,
        node_products: payload
      }
    }
  },
  effects: dispatch => ({
    async getStorage(user, rootState) {
      try {
        const {profile: {user: {username}}, axios} = rootState
        const data = await get(`${BASE_URL}/stores?user.username=${user || username}`, axios.config)
        dispatch.global.setStorage(data)
      }catch (error) { 
        extractError(error)
      }
    },
    async getClient(payload, rootState) {
      try {
        const {global: {storage}} = rootState
        const clients = storage.map(store=>{
          const {name, access_token} = store
          return new Client(typeBundle, {
              url: `https://${name}/api/graphql`,
              fetcherOptions: {
                headers: {
                  'X-Shopify-Storefront-Access-Token': access_token
                }
              }
            });
        })
        dispatch.global.setClient(clients)
      }catch (error) { 
        return extractError(error)
      }
    },
    async createDiscount(payload, rootState){
      try {
        const { config } = rootState.axios;
        const data = await post(`${BASE_URL}/catalog-rules`,
        payload, config)
      }catch(error) {
        return extractError(error)
      }
    }
  }),
};

export default global;

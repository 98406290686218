import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@shopify/polaris";
import { connect } from "react-redux";
import * as getValue from 'lodash.get';
import {ROLE, PATH} from '../../../constants'
import {
  HomeMajorMonotone,
  CustomersMajorMonotone,
  DiscountsMajorMonotone,
  MobileBackArrowMajorMonotone
} from "@shopify/polaris-icons";

const Header = ({user}) => {
  const type = getValue(user, 'role.type', '')
  const userLinkMark = type === ROLE.admin ? (
    <li className="Polaris-Navigation__ListItem">
              <div className="Polaris-Navigation__ItemWrapper">
                <Link
                  className="Polaris-Navigation__Item"
                  tabIndex={1}
                  data-polaris-unstyled="true"
                  to="/user"
                >
                  <div className="Polaris-Navigation__Icon">
                    <Icon source={CustomersMajorMonotone} />
                  </div>
                  <span className="Polaris-Navigation__Text">User</span>
                </Link>
              </div>
            </li>
  ): null
  return (
    <div>
      <nav className="Polaris-Navigation">
        <div
          className="Polaris-Navigation__PrimaryNavigation Polaris-Scrollable Polaris-Scrollable--vertical"
          data-polaris-scrollable="true"
        >
          <ul className="Polaris-Navigation__Section">
            <li className="Polaris-Navigation__ListItem">
              <div className="Polaris-Navigation__ItemWrapper">
                <Link
                  className="Polaris-Navigation__Item"
                  tabIndex={0}
                  data-polaris-unstyled="true"
                  to="/"
                >
                  <div className="Polaris-Navigation__Icon">
                    <Icon source={HomeMajorMonotone} />
                  </div>
                  <span className="Polaris-Navigation__Text">Home</span>
                </Link>
              </div>
            </li>
            {userLinkMark}
            <li className="Polaris-Navigation__ListItem">
              <div className="Polaris-Navigation__ItemWrapper">
                <Link
                  className="Polaris-Navigation__Item"
                  tabIndex={1}
                  data-polaris-unstyled="true"
                  to={PATH.discountCart}
                >
                  <div className="Polaris-Navigation__Icon">
                    <Icon source={DiscountsMajorMonotone} />
                  </div>
                  <span className="Polaris-Navigation__Text">Discount</span>
                </Link>
              </div>
            </li>
            <li className="Polaris-Navigation__ListItem">
              <div className="Polaris-Navigation__ItemWrapper">
                <Link
                  className="Polaris-Navigation__Item"
                  tabIndex={1}
                  data-polaris-unstyled="true"
                  to="/logout"
                >
                  <div className="Polaris-Navigation__Icon">
                    <Icon source={MobileBackArrowMajorMonotone} />
                  </div>
                  <span className="Polaris-Navigation__Text">Log out</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
const mapStateToProps = ({profile}) => ({
  user: profile.user
});
  
export default connect(
  mapStateToProps
)(Header);


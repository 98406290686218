import React from 'react';
import { hydrate } from 'react-dom';
import '@shopify/polaris/styles.css';
import './scss/main.css';
import {AppProvider} from '@shopify/polaris';
import store from './store';
import App from './App.jsx';
import enTranslations from '@shopify/polaris/locales/en.json';
import {Link as ReactRouterLink} from 'react-router-dom';

async function main() {
  hydrate (
    <AppProvider i18n={enTranslations} linkComponent={Link}>
      <App store={store} />
    </AppProvider>,
    document.getElementById('root')
  );
}
function Link({children, url = '', ...rest}) {
  // Use an regular a tag for external and download links
  if (isOutboundLink(url) || rest.download) {
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

function isOutboundLink(url) {
  return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
}
main();

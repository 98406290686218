import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from './partials/Header';
import {Frame, Spinner} from '@shopify/polaris';
import { STATUS_LOADING, STATUS_SUCCESS } from '../../constants';

const DefaultLayout = ({ children, pageStatus }) => {
  const navigationMarkup = (<Header/>);
  return (
    <div id="page">
      <Frame
        navigation={navigationMarkup}
      >

        <div id="page-content">
          {pageStatus === STATUS_LOADING && <div className="layout"><Spinner accessibilityLabel="Loading..." size="large" color="teal" /></div>}
          {pageStatus === STATUS_SUCCESS && children}
        </div>
      </Frame>
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node,
  pageStatus: PropTypes.number,
};

const mapStateToProps = ({ page }) => ({
  pageStatus: page.status,
});

export default connect(mapStateToProps)(DefaultLayout);

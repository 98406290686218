import { route } from '../store/hydrate';
import { get } from '../utils/http';
import axios from 'axios';
import { STATUS_LOADING, STATUS_SUCCESS, PATH } from '../constants';
import * as getValue from 'lodash.get'

route(PATH.user, async ({ store }) => {
  const {
    axios: { config }
  } = store.getState();
  const {
    dispatch: { user, page }
  } = store;
  page.setStatus(STATUS_LOADING);
  try {
    let getUserListQuery = `${config.backendUrl}/users`
    const getUserList = () => get(getUserListQuery, config);
    const getTotal = () =>
      axios
        .get(`${getUserListQuery}/count`, config)
    const getMe = () => 
      axios.get(`${getUserListQuery}/me`, config)
    const [userList, total, me] = await Promise.all([
      getUserList(),
      getTotal(),
      getMe()
    ]);
    const _total = getValue(total, 'data');
    user.setData({
        userList: userList || [],
        total: _total,
        me: me.data
    });
    page.setStatus(STATUS_SUCCESS);
  } catch (error) {
    page.setErrors(error);
  }
});

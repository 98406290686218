const axios = {
  state: {
    config: {},
  },
  reducers: {
    setConfig(state, payload) {
      return {
        ...state,
        config: payload,
      };
    },
    setToken(state, token) {
      return {
        ...state,
        config: {
          ...state.config,
          loggedIn: !!token,
          headers: {
            ...state.config.headers,
            Authorization: `Bearer ${token}`,
          },
        },
      };
    }
  },
};

export default axios;

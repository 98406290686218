import { post, get, put, deleteReq } from '../../utils/http';
import {BASE_URL} from '../../constants'
import * as getValue from 'lodash.get'
import {extractError} from '../../utils/helpers'
const rule = {
  state: {
    rules: [],
    cartRules: [],
    ruleDetail: {}
  },
  reducers: {
    setState(state, payload) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: dispatch => ({
    async createCartRule(payload, rootState){
      try {
        const { config } = rootState.axios;
        const data = await post(`${BASE_URL}/cart-rules`,
        payload, config)
        return data
      }catch(error) {
        return extractError(error)
      }
    },
    async createCodes(payload, rootState){
      try {
        const { config } = rootState.axios;
        const data = await post(`${BASE_URL}/create-codes`,
        payload, config)
        return data
      }catch(error) {
        return extractError(error)
      }
    },
    async deleteCode(payload, rootState) {
      try {
        const { config } = rootState.axios;
        const {id} = payload;
        const data = await deleteReq(`${BASE_URL}/coupon-codes/${id}`, config)
        return data
      }catch(error) {
        return extractError(error)
      }
    },
    async fetchCartRule(payload, rootState) {

      try {
        const { axios: {config} } = rootState;
        const { data } = rootState.rule;
        const query = getValue(payload, 'query', '')
        const cartRules = await get(`${BASE_URL}/cart-rules?${query}&_limit=-1`, config)
        dispatch.rule.setState({...data, cartRules})
        
      } catch(error) {
        return extractError(error);
      }
    
    },
    
    async fetchCartRuleEdit(payload, rootState) {

      try {
        const { axios: {config} } = rootState;
        const { data } = rootState.rule;
        const query = getValue(payload, 'query', '')
        const ruleDetail = await get(`${BASE_URL}/cart-rules?${query}`, config)
        dispatch.rule.setState({...data, ruleDetail})
        
      } catch(error) {
        return extractError(error);
      }
    
    },
    
    async fetchCatalogRule(payload, rootState) {

      try {
        const { axios: {config} } = rootState;
        const { data } = rootState.rule;
        const query = getValue(payload, 'query', '')
        const rules = await get(`${BASE_URL}/catalog-rules?${query}`, config)
        dispatch.rule.setState({...data, rules})

      } catch(error) {
        return extractError(error);
      }
    
    },
    async updateRule(payload, rootState) {
      try {
        const {id, values} = payload;
        const { data } = rootState.rule;
        const { config } = rootState.axios;
        const ruleDetail = await put(`${BASE_URL}/catalog-rules/${id}`, values, config)
        dispatch.rule.setState({...data, ruleDetail})
        return ruleDetail
      } catch(error) {
        return {error: extractError(error)}
      }
    },
    async updateCartRule(payload, rootState) {
      try {
        const {id, values} = payload;
        const { data } = rootState.rule;
        const { config } = rootState.axios;
        const ruleDetail = await put(`${BASE_URL}/cart-rules/${id}`, values, config)
        dispatch.rule.setState({...data, ruleDetail: [ruleDetail]})
        return ruleDetail
      } catch(error) {
        return extractError(error)
      }
    },
    async deleteRule(payload, rootState) {
      try {
        const {id} = payload;
        const { config } = rootState.axios;
        const { data } = rootState.rule;
        await deleteReq(`${BASE_URL}/catalog-rules/${id}`, config)
        dispatch.rule.setState({...data, ruleDetail: {}})
      } catch(error) {
        return extractError(error)
      }
    },
    async deleteCartRule(payload, rootState) {
      try {
        const {id} = payload;
        const { config } = rootState.axios;
        const { data } = rootState.rule;
        await deleteReq(`${BASE_URL}/cart-rules/${id}`, config)
        dispatch.rule.setState({...data, ruleDetail: {}})
      } catch(error) {
        return extractError(error)
      }
    }
  })
};

export default rule;

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Link } from "react-router-dom";
import {
  Layout,
  Page,
  Card,
  DataTable,
  Avatar,
  Button,
  Icon,
  ButtonGroup,
  Modal,
  TextContainer
} from "@shopify/polaris";
import * as getValue from "lodash.get";
import { EditMajorMonotone, DeleteMajorMonotone } from "@shopify/polaris-icons";
const User = ({ user = {}, profileAction, userAction }) => {
  let userList = getValue(user, "userList", [{}]);
  const [deleteId, setdeleteId] = useState()
  const [active, setActive] = useState();
  const [isLoading, setLoading] = useState();
  const handleChange = useCallback(() => setActive(!active), [active]);
  const handleChangeLoading = useCallback(() => setLoading(!isLoading), [isLoading]);
  const handleChangeDeleteID = useCallback((value) => setdeleteId(value), []);
  const deleteUser = async () => {
    handleChangeLoading()
    await profileAction.deleteUser({ id: deleteId });
    await userAction.getUser()
    handleChange()
  };
  let rows = userList.map((u) => {
    if (u) {
      const { id, username, email } = u;
      const stores = getValue(u, "stores");
      let _stores = stores && stores.map((s) => s.name);
      return [
        id,
        username,
        email,
        <div>{_stores && _stores.map((s) => <p>{s}</p>)}</div>,
        <ButtonGroup segmented>
          <Button
            titile="Edit"
          >
            <Link to={`/user/${id}`}>
              <Icon source={EditMajorMonotone} />
            </Link>
          </Button>
          <Button
            onClick={() => {
              handleChange()
              handleChangeDeleteID(id)
            }}
            title="Delete"
          >
            <Icon source={DeleteMajorMonotone} />
          </Button>
        </ButtonGroup>,
      ];
    }
  });
  rows.shift();

  return (
    <DefaultLayout>
      <Page
        title="User"
        additionalNavigation={
          <Avatar size="small" initials="AM" customer={false} />
        }
      >
        <div className="actions-toolbar">
          <Link className="no-textdeco" to="/register">
            <Button primary>Create User</Button>
          </Link>
        </div>

        <Layout>
          <Layout.Section>
            <Card>
              <DataTable
                columnContentTypes={["numeric", "text", "text", "text", "text"]}
                headings={["id", "Username", "Email", "Stores", ""]}
                rows={rows}
              />
            </Card>
          </Layout.Section>
        </Layout>
        <Modal
          open={active}
          onClose={handleChange}
          primaryAction={{
            content: "Cancel",
            onAction: handleChange,
            disabled: isLoading,
            loading: isLoading
          }}
          secondaryActions={[
            {
              content: "Confirm",
              onAction: deleteUser,
              disabled: isLoading,
              loading: isLoading
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                Are you sure?
              </p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </Page>
    </DefaultLayout>
  );
};

User.propTypes = {
  userAction: PropTypes.object,
};

const mapStateToProps = ({ user }) => ({
  user: user.data,
});

const mapActionToProps = ({ profile, user }) => ({
  profileAction: profile,
  userAction: user
});

export default connect(mapStateToProps, mapActionToProps)(User);

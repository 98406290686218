import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Formik, Form } from "formik";
import FieldComp from "../../Form/Field";
import { connect } from "react-redux";
import { catalogRuleSchema } from "../../../utils/formSchema";
import { PLACEHOLDER_IMAGE } from "../../../constants";
import * as getValue from "lodash.get";
import { flatten, isEmpty, omit, set, concat, uniqBy } from "lodash";
import Client from "graphql-js-client";
import typeBundle from "../../../types";
import { useHistory } from "react-router-dom";
import { PATH } from "../../../constants";
import moment from "moment";
import { queryCollection, queryProduct } from "../../../utils/helpers";
import {
  Layout,
  Page,
  Card,
  Avatar,
  DatePicker,
  TextStyle,
  Stack,
  Checkbox,
  Button,
  OptionList,
  InlineError,
  Toast,
  ResourceList,
  ResourceItem,
  Thumbnail,
  ChoiceList,
  TextField,
  Popover,
  Tag,
  Modal,
  Filters,
  Select,
} from "@shopify/polaris";

import MomentUtils from "@date-io/moment";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
const DiscountDetail = ({
  global,
  profile,
  globalAction,
  rule,
  ruleAction,
  path,
}) => {
  const editRule = getValue(rule, "rules[0]", {});

  const [ruleId, setRuleId] = useState(0);
  const history = useHistory();
  const { storage } = global;

  const today = moment();
  const optionsList =
    storage && storage.map((s) => ({ value: s.name, label: s.name }));
  const [selectedItems, setSelectedItems] = useState([]);

  const [discountAmout, setDiscountAmout] = useState("0");
  const [discountAmoutLimit, setDiscountAmoutLimit] = useState(null);
  const [timezone, setTimezone] = useState("Etc/UTC");
  const [startTime, setStartTime] = useState(today.toDate());
  const [endTime, setEndTime] = useState(today.toDate());

  const [includeDiscountPrice, setIncludeDiscountPrice] = useState(false);

  const handleChangeIncludeDiscountPrice = useCallback(
    (value) => setIncludeDiscountPrice(value),
    []
  );
  const [selectedApply, setSelectedApply] = useState("fixed_amount");
  const handleChangeApply = useCallback((value) => setSelectedApply(value), []);
  const [popoverEndTimeActive, setPopoverEndTimeActive] = useState();
  const [popoverStartTimeActive, setPopoverStartTimeActive] = useState();
  const [queryValue, setQueryValue] = useState(null);
  const [resourceListLoading, setResourceListLoading] = useState(true);
  const [isFreeShipping, setisFreeShipping] = useState(false);
  const [customerGroupSelected, setcustomerGroupSelected] = useState([]);
  const [popoverStartDateActive, setPopoverStartDateActive] = useState();
  const [popoverEndDateActive, setPopoverEndDateActive] = useState();
  const [fromDateStr, setFromDateStr] = useState(today.format("YYYY-MM-DD"));
  const [toDateStr, setToDateStr] = useState(today.format("YYYY-MM-DD"));
  const [statusSelected, setstatusSelected] = useState([
    getValue(rule, "rules[0].status", true),
  ]);

  const [ignoreSubsequent, setIgnoreSubsequent] = useState(false);
  const [ignoreSubsequentByItem, setIgnoreSubsequentByItem] = useState(false);
  const [activeModal, setActiveModal] = useState();
  const [createDiscountError, setCreateDiscountError] = useState("");
  const [createDiscountSuccessfully, setCreateDiscountSuccessfully] = useState(
    ""
  );
  const [storeSelected, setStoreSelected] = useState([]);
  const [customerIncludeTags, setCustomerIncludeTags] = useState("");
  const [customerExcludeTags, setCustomerExcludeTags] = useState("");
  const [condition, setCondition] = useState("");
  const [{ month, year }, setDate] = useState({
    month: today.month(),
    year: today.year(),
  });
  const toggleModal = useCallback(() => setActiveModal(!activeModal), [
    activeModal,
  ]);
  const handleChangeDiscountAmout = useCallback(
    (value) => setDiscountAmout(value),
    []
  );
  const handleChangeTimezone = useCallback((value) => setTimezone(value), []);
  const handleChangeStartTime = useCallback((value) => setStartTime(value), []);
  const handleChangeEndTime = useCallback((value) => setEndTime(value), []);
  const handleChangeFreeShipping = useCallback(
    (newChecked) => setisFreeShipping(newChecked),
    []
  );

  const togglePopoverEndTimeActive = useCallback(
    () => setPopoverEndTimeActive((value) => !value),
    []
  );
  const togglePopoverStartTimeActive = useCallback(
    () => setPopoverStartTimeActive((value) => !value),
    []
  );
  const [selectedDates, setSelectedDates] = useState({
    start: today.toDate(),
    end: today.toDate(),
  });
  const [{ toMonth, toYear }, setToDate] = useState({
    toMonth: today.month(),
    toYear: today.year(),
  });
  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleChangeIgnoreSubsequent = useCallback(
    (value) => setIgnoreSubsequent(value),
    []
  );
  const [selectedToDates, setSelectedToDates] = useState({
    start: moment(selectedDates["end"]).add(1, "days").toDate(),
    end: moment(selectedDates["end"]).add(1, "days").toDate(),
  });
  const handleStoreChange = useCallback((value) => setStoreSelected(value), []);
  const handleChangeCustomerGroup = useCallback(
    (value) => setcustomerGroupSelected(value),
    []
  );
  const togglePopoverStartDateActive = useCallback(
    () => setPopoverStartDateActive((value) => !value),
    []
  );
  const togglePopoverEndDateActive = useCallback(
    () => setPopoverEndDateActive((value) => !value),
    []
  );
  const handleChangeCustomerIncludeTags = useCallback(
    (value) => setCustomerIncludeTags(value),
    []
  );
  const handleChangeCustomerExcludeTags = useCallback(
    (value) => setCustomerExcludeTags(value),
    []
  );
  const handleStatusChange = useCallback(
    (value) => setstatusSelected(value),
    []
  );
  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );
  const handleToMonthChange = useCallback(
    (toMonth, toYear) => setToDate({ toMonth, toYear }),
    []
  );
  const statusOptions = [
    { label: "Active", value: true },
    { label: "Deactive", value: false },
  ];
  const renderCustomerIncludeTagsInput = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelText="Choose customers with specific tags"
          onChange={handleChangeCustomerIncludeTags}
          value={customerIncludeTags}
          name="customer_include_tags"
          placeholder="Comma separated list of tags"
          type="text"
        />
      ),
    [handleChangeCustomerIncludeTags, customerIncludeTags]
  );
  const renderCustomerExcludeTagsInput = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelText="Choose customers with specific tags"
          onChange={handleChangeCustomerExcludeTags}
          value={customerExcludeTags}
          name="customer_exclude_tags"
          placeholder="Comma separated list of tags"
          type="text"
        />
      ),
    [handleChangeCustomerExcludeTags, customerExcludeTags]
  );
  const customerListItem = [
    {
      label: "Only show to signed in customers",
      value: "customer_include_tags",
      renderChildren: renderCustomerIncludeTagsInput,
    },
    {
      label: "Exclude signed in customers with specific tags",
      value: "customer_exclude_tags",
      renderChildren: renderCustomerExcludeTagsInput,
    },
  ];

  const applyListItem = [
    { label: "Apply as percentage of original", value: "percentage" },
    { label: "Apply as fixed amount of original", value: "fixed_amount" },
  ];
  const initiaRulelValues = getValue(rule, "rules[0]", {
    name: editRule.name || "",
    description: editRule.description || "",
    status: editRule.status || false,
    stores: editRule.stores || [],
    priority: editRule.priority || 0,
    condition: {},
    apply: editRule.apply || applyListItem[0].value,
    discount_amount: editRule.discount_amount || 0,
    customer_groups: editRule.customer_groups || customerListItem[0].value,
  });
  const toggleToast = useCallback(
    () => setCreateDiscountSuccessfully((active) => !active),
    []
  );
  const toastMarkup = createDiscountSuccessfully ? (
    <Toast content="Successfully" onDismiss={toggleToast} duration={2000} />
  ) : null;
  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      onQueryChange={(queryValue) => {
        handleQueryValueChange(queryValue);
      }}
    >
      <div style={{ paddingLeft: "8px" }}>
        <Button
          onClick={() => {
            setSelectedItems([]);
            fetchProducts(false, false, queryValue);
          }}
        >
          Apply
        </Button>
      </div>
    </Filters>
  );
  const productListMakrup = (clear) => {
    if (clear) {
      return [];
    }
    let arr =
      !isEmpty(condition) && !isEmpty(global[condition]) && 
      global[condition].map((item, index) => {
        const list = getValue(item, "list", []);
        const { shop_name } = item;
        return list.map((l, idx) => {
          const {
            node: { title, images, id, image },
          } = l;
          return {
            title,
            images,
            id: `[${index}].list[${idx}]`,
            image,
            shop_name,
            node_id: id,
          };
        });
      });
    arr = flatten(arr);
    return arr;
  };
  const renderItem = (item) => {
    const { id, title, image, images, shop_name } = item;
    const source =
      condition === "products"
        ? getValue(image, "src", PLACEHOLDER_IMAGE)
        : getValue(images, "edges[0].node.src", PLACEHOLDER_IMAGE);
    const media = <Thumbnail source={source} alt={title}></Thumbnail>;

    return (
      <ResourceItem
        id={id}
        title={title}
        media={media}
        shop_name={shop_name}
        key={`key-resouce-${id}`}
      >
        <h3>
          <TextStyle variation="strong">{title}</TextStyle>
        </h3>
        Store: {shop_name}
      </ResourceItem>
    );
  };
  const onSubmitForm = async (values, actions) => {
    let data = Object.assign({}, values);
    const _startD = moment(fromDateStr);
    const _startT = moment(startTime, "HH:mm");
    const _endT = moment(endTime, "HH:mm");
    const _endD = moment(toDateStr);
    if (
      _endD.isBefore(_startD) ||
      (_endD.isSame(_startD) && _endT.isSameOrBefore(_startT))
    ) {
      setCreateDiscountError("End date time is not valid!");
      return;
    }
    set(
      data,
      "from",
      moment
        .tz(fromDateStr, timezone)
        .set({
          hours: _startT.hours(),
          minutes: _startT.minutes(),
        })
        .format()
    );
    set(
      data,
      "to",
      moment
        .tz(toDateStr, timezone)  
        .set({
          hours: _endT.hours(),
          minutes: _endT.minutes(),
        })
        .format()
    );
    // return;
    // set(data, "to", moment(toDateStr).set({
    //   hours: _endT.hours(),
    //   minutes: _endT.minutes()
    // }).tz('etc/UTC').format());
    set(data, "condition", getValue(rule, "rules[0].condition"));
    set(data, "stores", getValue(rule, "rules[0].stores"));
    set(data, "status", statusSelected);
    set(data, "apply", selectedApply);
    set(data, "customer_include_tags", customerIncludeTags);
    set(data, "customer_exclude_tags", customerExcludeTags);
    set(data, "has_free_shipping", isFreeShipping);
    set(data, "discount_amount", discountAmout);
    set(data, "discount_amount_limit", discountAmoutLimit);

    set(data, "ignore_subsequent", ignoreSubsequent);
    set(data, "include_discount_price", includeDiscountPrice);
    set(
      data,
      "stores",
      storage.filter((s) => storeSelected.includes(s.name))
    );

    set(data, "timezone", timezone);
    set(data, "toTime", moment(endTime, "HH:mm").format("HH:mm:ss.SSS"));
    set(data, "fromTime", moment(startTime, "HH:mm").format("HH:mm:ss.SSS"));
    set(
      data,
      "only_signed_in",
      customerGroupSelected.includes("customer_include_tags")
    );
    data = omit(data, ["created_at", "updated_at"]);
    const { id } = data;
    let _response;
    if (PATH.discountCreate !== path) {
      _response = await ruleAction.updateRule({ id, values: data });
    } else {
      data = omit(data, ["id"]);
      const user_id = getValue(profile, "user.id");
      set(data, "user", { id: user_id });
      _response = await globalAction.createDiscount(data);
    }
    if (_response && _response.error) {
      setCreateDiscountError(_response.error);
    } else {
      setCreateDiscountSuccessfully(true);
      history.push(PATH.discount);
    }
  };
  const fetchProducts = (isNext = false, isClear = false, hasQuery) => {
    if (isClear) {
      globalAction.setProducts([]);
      globalAction.setCollections([]);
      globalAction.setNodeProducts([]);
      productListMakrup();
      return;
    }
    let products = [];
    let collections = [];
    let nodeProducts = [];
    const fetchFnc = async () => {
      if (isNext) {
        const _nodeProducts = getValue(global, "node_products", []);
        const canFetch = getValue(_nodeProducts, "[0].list");
        if (isEmpty(canFetch)) {
          return;
        }
        setResourceListLoading(true);
        Promise.all(
          _nodeProducts.map(async (nodeItem) => {
            const { client, list } = nodeItem;
            return client.fetchNextPage(list);
          })
        ).then((values) => {
          values.map((v, index) => {
            const _client = _nodeProducts[index].client;
            const name = _nodeProducts[index].shop_name;
            const c = getValue(v, "data.shop.collections.edges", []);
            const p = flatten(
              getValue(v, "data.shop.products.edges", []).map((product) => {
                const productTitle = getValue(product, "node.title", "");
                return getValue(product, "node.variants.edges", []).map(
                  (variant) => {
                    return {
                      ...variant,
                      node: {
                        ...variant.node,
                        title: `${productTitle} - ${variant.node.title}`,
                      },
                    };
                  }
                );
              }, [])
            );
            products.push({ shop_name: name, list: p });
            collections.push({ shop_name: name, list: c });
            nodeProducts.push({
              shop_name: name,
              list: getValue(v, "model", []),
              client: _client,
            });
          });
          globalAction.setProducts(
            concat(getValue(global, "products", []), products)
          );
          globalAction.setCollections(
            concat(getValue(global, "collections", []), collections)
          );
          globalAction.setNodeProducts(nodeProducts);
          productListMakrup();
          setResourceListLoading(false);
        });
        return;
      }

      setResourceListLoading(true);
      const clients = storeSelected.map((store) => {
        const _name = getValue(store, "name", "");
        const filtedStore = storage.find((s) => {
          if (typeof s !== "object") {
            return false;
          }
          const storeName = getValue(s, "name", "");
          return typeof store === "string"
            ? store.localeCompare(storeName) === 0
            : _name.localeCompare(storeName) === 0;
        });
        const { name, access_token } = filtedStore;
        return {
          client: new Client(typeBundle, {
            url: `https://${name}/api/graphql`,
            fetcherOptions: {
              headers: {
                "X-Shopify-Storefront-Access-Token": access_token,
              },
            },
          }),
          name,
        };
      });
      const _querry = hasQuery ? `title:*${queryValue}*` : "";
      Promise.all(
        clients.map(async (client) => {
          const query = client.client.query((root) => {
            root.add("shop", (shop) => {
              shop.add("name");
              if(condition === 'products') {
                queryProduct(shop, _querry);
              }
              if(condition === 'collections') {
                queryCollection(shop, _querry);
              }
            });
          });
          return client.client.send(query);
        })
      ).then((values) => {
        values.map((v, index) => {
          if(isEmpty(v)) {
            return;
          }
          const _client = clients[index];
          const name = clients[index].name;
          const c = getValue(v, "data.shop.collections.edges", []);
          const p = flatten(
            getValue(v, "data.shop.products.edges", []).map((product) => {
              const productTitle = getValue(product, "node.title", "");
              return getValue(product, "node.variants.edges", []).map(
                (variant) => {
                  return {
                    ...variant,
                    node: {
                      ...variant.node,
                      title: `${productTitle} - ${variant.node.title}`,
                    },
                  };
                }
              );
            }, [])
          );
          products.push({ shop_name: name, list: p });
          collections.push({ shop_name: name, list: c });
          nodeProducts.push({
            shop_name: name,
            list: v.model.shop.products,
            client: _client.client,
          });
        });
        globalAction.setProducts(products);
        globalAction.setCollections(collections);
        globalAction.setNodeProducts(nodeProducts);
        productListMakrup();
        setResourceListLoading(false);
      });
    };
    fetchFnc();
  };
  useEffect(fetchProducts, [condition]);
  useEffect(() => {
    if (!isEmpty(editRule) && path !== PATH.discountCreate) {
      const {
        customer_exclude_tags,
        customer_include_tags,
        status,
        from,
        to,
        only_signed_in,
        has_free_shipping,
        fromTime,
        toTime,
        id,
        ignore_subsequent,
        include_discount_price,
        apply,
        discount_amount
      } = editRule;
      if (ruleId !== id) {
        setRuleId(id);
        const stores = getValue(editRule, "stores", []);
        const _timezone = getValue(editRule, "timezone");
        if (apply !== selectedApply) {
          handleChangeApply(apply);
        }
        if (String(discount_amount) !== discountAmout) {
          handleChangeDiscountAmout(String(discount_amount));
        }
        if (_timezone !== timezone && _timezone) {
          setTimezone(_timezone);
        }
        const _from = moment(from).tz(_timezone || "etc/UTC");
        // console.log(_timezone, from, _from.format("YYYY-MM-DD"), moment(from).tz(_timezone).format("YYYY-MM-DD"))
        const _to = moment(to).tz(_timezone || "etc/UTC");
        if (fromTime !== startTime && fromTime) {
          setStartTime(moment(fromTime, "HH:mm").toDate());
        }
        if (endTime !== toTime && toTime) {
          setEndTime(moment(toTime, "HH:mm").toDate());
        }
        if (fromDateStr !== from) {
          setFromDateStr(_from.format("YYYY-MM-DD"));
        }
        if (toDateStr !== to) {
          setToDateStr(_to.format("YYYY-MM-DD"));
        }
        if (ignore_subsequent !== ignoreSubsequent) {
          setIgnoreSubsequent(ignore_subsequent);
        }
        if (include_discount_price !== includeDiscountPrice) {
          setIncludeDiscountPrice(include_discount_price);
        }
        setstatusSelected([status]);
        setisFreeShipping(has_free_shipping);
        setcustomerGroupSelected([
          only_signed_in && "customer_include_tags",
          customer_exclude_tags && "customer_exclude_tags",
        ]);
        setCustomerExcludeTags(customer_exclude_tags || "");
        setCustomerIncludeTags(customer_include_tags || "");
        setStoreSelected(stores.map((store) => store.name));
      }
    }
  }, [editRule]);
  const storeSelectMarkup = optionsList.length ? (
    <Card>
      <OptionList
        allowMultiple
        title="Stores"
        options={optionsList}
        selected={storeSelected}
        onChange={handleStoreChange}
      />
    </Card>
  ) : null;
  return (
    <DefaultLayout>
      <Page
        title="Create Discount"
        additionalNavigation={
          <Avatar size="small" initials="AM" customer={false} />
        }
      >
        {toastMarkup}
        <Layout>
          <Layout.Section>
            <Formik
              initialValues={initiaRulelValues}
              validationSchema={catalogRuleSchema}
              onSubmit={onSubmitForm}
            >
              {({ isValid, isSubmitting }) =>
                editRule && (
                  <Form>
                    <Button
                      primary
                      submit
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Save
                    </Button>
                    {createDiscountError && (
                      <InlineError message={createDiscountError} />
                    )}
                    <Layout.AnnotatedSection title="Discount Information">
                      <Card>
                        <Card.Section>
                          <FieldComp labelText="Rule name" name="name" />
                        </Card.Section>
                        <Card.Section>
                          <FieldComp
                            labelText="Description"
                            name="description"
                            as="textarea"
                          />
                        </Card.Section>
                        <Card.Section>
                          <ChoiceList
                            title="Status"
                            name="status"
                            choices={statusOptions}
                            selected={statusSelected}
                            onChange={handleStatusChange}
                          ></ChoiceList>
                        </Card.Section>
                      </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection title="Availability">
                      <Card>
                        <Card.Section>{storeSelectMarkup}</Card.Section>
                        <Card.Section>
                          <ChoiceList
                            title="Customer Group"
                            choices={customerListItem}
                            selected={customerGroupSelected}
                            onChange={handleChangeCustomerGroup}
                            allowMultiple={true}
                          />
                        </Card.Section>
                        <Card.Section>
                          <Select
                            label="Time Zones"
                            options={moment.tz.names()}
                            value={timezone}
                            onChange={handleChangeTimezone}
                          />
                        </Card.Section>
                        <Card.Section>
                          <div className="ApplicabilityDate__Row">
                            <Stack>
                              <Popover
                                active={popoverStartDateActive}
                                onClose={togglePopoverStartDateActive}
                                ariaHaspopup={false}
                                sectioned
                                activator={
                                  <div onClick={togglePopoverStartDateActive}>
                                    <FieldComp
                                      name="from"
                                      type="text"
                                      labelText="Start Date"
                                      readOnly={true}
                                      value={fromDateStr}
                                      // connectedRight={
                                      //   <TextField
                                      //     labelHidden
                                      //     type="text"
                                      //     placeholder="Time"
                                      //     name="start_time"
                                      //     value={moment(
                                      //       getValue(editRule, "from", "")
                                      //     ).format("HH:mm")}
                                      //   />
                                      // }
                                    />
                                  </div>
                                }
                              >
                                <DatePicker
                                  month={month}
                                  year={year}
                                  onChange={(date) => {
                                    setSelectedDates(date);
                                    ruleAction.setState({
                                      ...rule,
                                      rules: [
                                        {
                                          ...editRule,
                                          from: moment(date.start).format(),
                                        },
                                      ],
                                    });
                                    setFromDateStr(
                                      moment(date.start).format("YYYY-MM-DD")
                                    );
                                    setPopoverStartDateActive(false);
                                  }}
                                  onMonthChange={handleMonthChange}
                                  selected={selectedDates}
                                  allowRange={false}
                                  // disableDatesBefore={moment()
                                  //   .subtract(1, "days")
                                  //   .toDate()}
                                ></DatePicker>
                              </Popover>

                              <div className="time-group">
                                <Popover
                                  ariaHaspopup={false}
                                  sectioned
                                  fluidContent={true}
                                  active={popoverStartTimeActive}
                                  onClose={togglePopoverStartTimeActive}
                                  activator={
                                    <div onClick={togglePopoverStartTimeActive}>
                                      <TextField
                                        labelHidden
                                        type="text"
                                        placeholder="HH:MM"
                                        name="start_time"
                                        value={moment(startTime).format(
                                          "HH:mm"
                                        )}
                                        readOnly
                                      />
                                    </div>
                                  }
                                >
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <TimePicker
                                      onChange={handleChangeStartTime}
                                      variant="static"
                                      value={startTime}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Popover>
                              </div>
                              <Popover
                                active={popoverEndDateActive}
                                onClose={togglePopoverEndDateActive}
                                ariaHaspopup={false}
                                sectioned
                                activator={
                                  <div onClick={togglePopoverEndDateActive}>
                                    <FieldComp
                                      name="to"
                                      type="text"
                                      labelText="End Date"
                                      readOnly={true}
                                      value={toDateStr}
                                    />
                                  </div>
                                }
                              >
                                <DatePicker
                                  month={toMonth}
                                  year={toYear}
                                  onChange={(date) => {
                                    setSelectedToDates(date);
                                    ruleAction.setState({
                                      ...rule,
                                      rules: [
                                        {
                                          ...editRule,
                                          to: moment(date.start).format(),
                                        },
                                      ],
                                    });
                                    setToDateStr(
                                      moment(date.start).format("YYYY-MM-DD")
                                    );
                                    setPopoverStartDateActive(false);
                                  }}
                                  onMonthChange={handleToMonthChange}
                                  selected={selectedToDates}
                                  allowRange={false}
                                  // disableDatesBefore={
                                  //   moment().subtract(1, "days").toDate()
                                    // today.isSameOrBefore(moment(selectedDates["end"]).subtract(1, "days")) ?
                                    // new Date() :
                                    // moment(selectedDates["end"]).subtract(1, "days").toDate()
                                  // }
                                ></DatePicker>
                              </Popover>
                              <div className="time-group">
                                <Popover
                                  ariaHaspopup={false}
                                  sectioned
                                  fluidContent={true}
                                  active={popoverEndTimeActive}
                                  onClose={togglePopoverEndTimeActive}
                                  activator={
                                    <div onClick={togglePopoverEndTimeActive}>
                                      <TextField
                                        labelHidden
                                        type="text"
                                        name="end_time"
                                        placeholder="HH:mm"
                                        value={moment(endTime).format("HH:mm")}
                                        readOnly={true}
                                      />
                                    </div>
                                  }
                                >
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <TimePicker
                                      onChange={handleChangeEndTime}
                                      variant="static"
                                      value={endTime}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Popover>
                              </div>
                            </Stack>
                          </div>
                        </Card.Section>
                        <Card.Section>
                          <FieldComp
                            labelText="Priority"
                            name="priority"
                            type="number"
                            min="0"
                            max="1000"
                          />
                          <TextStyle variation="subdued">
                            *(0-1000 : 0 = Highest , 1000 = Lowest)
                          </TextStyle>
                        </Card.Section>
                        <Card.Section>
                          <Checkbox
                            name="include_discount_price"
                            checked={includeDiscountPrice}
                            label="Calculate the discount based on discounted price"
                            onChange={handleChangeIncludeDiscountPrice}
                          />
                        </Card.Section>
                        <Card.Section>
                          <Checkbox
                            name="is_ignore_subsequent"
                            checked={ignoreSubsequent}
                            label="Ignore subsequent rules"
                            onChange={handleChangeIgnoreSubsequent}
                          />
                        </Card.Section>
                      </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection title="Offer">
                      <Card>
                        <Card.Section title="Apply discount for these products only">
                          <Stack>
                            <Button
                              onClick={() => {
                                setCondition("products");
                                setQueryValue("");
                                setSelectedItems([]);
                                toggleModal();
                              }}
                            >
                              Choose Products
                            </Button>
                            <Button
                              onClick={() => {
                                setCondition("collections");
                                setQueryValue("");
                                setSelectedItems([]);
                                toggleModal();
                              }}
                            >
                              Choose Collections
                            </Button>
                          </Stack>
                          <div className="InventoryTags">
                            {getValue(rule, "rules[0].condition.list", []).map(
                              (list, idx) => {
                                let shop_name = getValue(
                                  list,
                                  "shop_name",
                                  ""
                                ).split(".")[0];
                                return (
                                  list &&
                                  list.list.map((item, index) => {
                                    const title = getValue(item, "node.title");
                                    const id = getValue(item, "node.id");
                                    return title ? (
                                      <Tag
                                        onRemove={() => {
                                          let _list = getValue(list, "list");
                                          _list.splice(index, 1);
                                          set(
                                            editRule,
                                            `condition[list][${idx}][list]`,
                                            _list
                                          );
                                          ruleAction.setState({
                                            ...rule,
                                            rules: [editRule],
                                          });
                                        }}
                                        key={shop_name + " - " + title}
                                      >
                                        <strong>{shop_name} : </strong>
                                        {title}
                                      </Tag>
                                    ) : null;
                                  })
                                );
                              }
                            )}
                          </div>
                        
                        </Card.Section>
                        <Modal
                          open={activeModal}
                          onClose={() => {
                            toggleModal();
                            setCondition("");
                          }}
                          title={`Select ${condition}`}
                          primaryAction={{
                            content:
                              condition && !isEmpty(storeSelected)
                                ? "Save"
                                : "Close",
                            onAction: () => {
                              if (condition && !isEmpty(storeSelected)) {
                                const globlCondition = getValue(
                                  global,
                                  condition
                                );
                                let _obj = {
                                  type: condition,
                                  list: storeSelected.map((s) => ({
                                    shop_name: s,
                                    list: [],
                                  })),
                                };
                                selectedItems.map((item) => {
                                  set(
                                    _obj,
                                    "list" + item,
                                    getValue(global, condition + item)
                                  );
                                });
                                const applyCondition = getValue(rule, "rules[0].condition", [])
                                const _apType = getValue(
                                  applyCondition,
                                  "type",
                                  ""
                                );
                                const _type = getValue(_obj, "type", "");
                                if (_apType !== _type) {
                                  ruleAction.setState({
                                    ...rule,
                                    rules: [
                                      {
                                        ...editRule,
                                        condition: _obj,
                                        stores: global.storage.filter((store) =>
                                          storeSelected.includes(store.name)
                                        ),
                                      },
                                    ],
                                  });
                                } else {
                                  let _list = getValue(_obj, "list", []);
                                  let mergeList = _list.map((o, i) => {
                                    let _arr = getValue(o, "list", []);
                                    const shop_name = getValue(
                                      o,
                                      "shop_name"
                                    );
                                    let willMergeList = getValue(
                                      applyCondition,
                                      "list",
                                      []
                                    ).find((o) => o.shop_name === shop_name)
                                      .list;
                                    let concatArr = _arr.concat(
                                      willMergeList
                                    );
                                    const uniqconcatArr = uniqBy(
                                      concatArr,
                                      "node.id"
                                    );
                                    set(o, "list", uniqconcatArr);
                                    return o;
                                  });
                                  set(_obj, 'list', mergeList)
                                  ruleAction.setState({
                                    ...rule,
                                    rules: [
                                      {
                                        ...editRule,
                                        condition: _obj,
                                        stores: global.storage.filter((store) =>
                                          storeSelected.includes(store.name)
                                        ),
                                      },
                                    ],
                                  });
                                }
                                ruleAction.setState({
                                  ...rule,
                                  rules: [
                                    {
                                      ...editRule,
                                      condition: _obj,
                                      stores: global.storage.filter((store) =>
                                        storeSelected.includes(store.name)
                                      ),
                                    },
                                  ],
                                });
                              }
                              toggleModal();
                              setCondition("");
                            },
                          }}
                          onScrolledToBottom={() => {
                            fetchProducts(true);
                          }}
                        >
                          <Modal.Section>
                            {condition && !isEmpty(storeSelected) ? (
                              <div className="condition-list">
                                <ResourceList
                                  resourceName={{
                                    singular: condition,
                                    plural: condition,
                                  }}
                                  items={productListMakrup()}
                                  renderItem={renderItem}
                                  selectedItems={selectedItems}
                                  onSelectionChange={setSelectedItems}
                                  selectable
                                  loading={resourceListLoading}
                                  filterControl={filterControl}
                                ></ResourceList>
                              </div>
                            ) : (
                              <div>Please select store</div>
                            )}
                          </Modal.Section>
                        </Modal>
                      </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection title="Actions">
                      <Card>
                        <Card.Section>
                          <Checkbox
                            name="is_free_shipping"
                            checked={isFreeShipping}
                            label="Free Shipping"
                            onChange={handleChangeFreeShipping}
                          />
                        </Card.Section>
                        <Card.Section>
                          <div className="conected-row">
                            <div>
                              <Select
                                label="Apply"
                                name="apply"
                                options={applyListItem}
                                onChange={handleChangeApply}
                                value={selectedApply}
                              ></Select>
                            </div>
                            <div>
                              <TextField
                                label="Discount Amount"
                                type="number"
                                name="discount_amount"
                                value={discountAmout}
                                onChange={handleChangeDiscountAmout}
                                min="0"
                              ></TextField>
                            </div>
                            <div>
                              <TextField
                                label="Discount Amount Limit"
                                type="number"
                                name="discount_amount"
                                value={discountAmout}
                                onChange={setDiscountAmoutLimit}
                                min="0"
                              ></TextField>
                            </div>
                          </div>
                        </Card.Section>
                      </Card>
                    </Layout.AnnotatedSection>
                    <div className="actions">
                      {createDiscountError && (
                        <div style={{ marginBottom: "20px}" }}>
                          <InlineError message={createDiscountError} />
                        </div>
                      )}
                      <Button
                        primary
                        submit
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                )
              }
            </Formik>
          </Layout.Section>
        </Layout>
      </Page>
    </DefaultLayout>
  );
};

DiscountDetail.propTypes = {
  page: PropTypes.object,
  rule: PropTypes.object,
};
const mapActionToProps = ({ global, rule }) => ({
  globalAction: global,
  ruleAction: rule,
});
const mapStateToProps = ({ global, profile, rule }) => ({
  global,
  profile,
  rule,
});

export default connect(mapStateToProps, mapActionToProps)(DiscountDetail);

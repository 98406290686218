import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DefaultLayout from "../../layouts/DefaultLayout";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import {PATH} from "../../../constants";
import {moneyFormat} from '../../../utils/helpers'
import * as getValue from "lodash.get";
import {
  Layout,
  Page,
  Tabs,
  Card,
  DataTable,
  Badge,
  Avatar,
  Icon,
  Modal,
  ButtonGroup,
  Button,
  TextContainer,
  Tooltip
} from "@shopify/polaris";
import {
  PlayMajorMonotone,
  DeleteMajorMonotone,
  EditMajorMonotone,
  PauseMajorMonotone
} from "@shopify/polaris-icons";

const Disccount = ({ page, profile, rule, ruleAction }) => {

  const { status } = page;
  const history = useHistory()
  const [activeDelete, setactiveDelete] = useState();
  const [active, setActive] = useState();
  const [deleteId, setdeleteId] = useState();
  const [activeId, setactiveId] = useState();
  const [isLoading, setLoading] = useState();

  const handleChangeDelete = useCallback(() => setactiveDelete(!activeDelete), [
    activeDelete,
  ]);
  const handleChangeActive = useCallback(() => setActive(!active), [active]);
  const handleChangeLoading = useCallback(() => setLoading(!isLoading), [
    isLoading,
  ]);
  const handleChangeDeleteID = useCallback((value) => setdeleteId(value), []);
  const handleChangeActiveID = useCallback((value) => setactiveId(value), []);
  const deleteRule = async () => {
    const {
      user: { id },
    } = profile;
    handleChangeLoading();
    await ruleAction.deleteRule({ id: deleteId });
    await ruleAction.fetchCatalogRule({ query: `user.id=${id}` });
    setLoading(false);
    handleChangeDelete();
  };

  const activeRule = async () => {
    const {
      user: { id },
    } = profile;
    handleChangeLoading();
    const { rules } = rule;
    const willlUpdateActiveRule = rules.find(o => o.id === activeId)
    const willUpdateStatus = getValue(willlUpdateActiveRule, 'status', false)
    await ruleAction.updateRule({ id: activeId, values: { status : !willUpdateStatus} });
    await ruleAction.fetchCatalogRule({ query: `user.id=${id}` });
    setLoading();
    handleChangeActive();
  };
  const { rules } = rule;
  const rows = [
    ["ALL10", <Badge status="success">Active</Badge>, "0 used", "From May 15"],
    ["ALL15", <Badge status="success">Active</Badge>, "0 used", "From May 15"],
    ["ALL20", <Badge status="success">Active</Badge>, "0 used", "From May 15"],
  ];
  const _rows =
    rules &&
    rules.map((r) => {
      const { id, name, status, count, from, to, total_amount, toTime, timezone } = r;
      const _fd = moment(from);
      const _td = moment(to);
      const _endT = moment(toTime, "HH:mm");
      const _to = moment(to).tz(timezone || "etc/UTC").set({
        hours: _endT.hours(),
        minutes: _endT.minutes(),
      });
      const _today = moment().tz(timezone || "etc/UTC");
      const displayFromM = _fd.format("MMMM");
      const displayFromD = _fd.format('DD');
      const displayToM = _td.format("MMMM");
      const displayToD = _td.format('DD');
      return [
        name,
        <Badge status={status ? (!_to.isBefore(_today)? "success" : "") : ""}>
          {
            _to.isBefore(_today) ? 'Expired' :
          (status ? "Active" : "Deactive")
          }
        </Badge>,
        `${count || 0} used`,
        `${moneyFormat(total_amount*100, '${{amount}}')}`, // Shopify rounding
        <div>
          From {displayFromM} {displayFromD}
          <br />
          To {displayToM} {displayToD}
        </div>,
        <ButtonGroup segmented>
        <Tooltip content="Edit">

          <Button>
            <Link to={`/discount/${id}`} title="Edit">
              <Icon source={EditMajorMonotone} />
            </Link>
          </Button>
        </Tooltip>
        <Tooltip content="Delete">
        <Button
          onClick={() => {
            handleChangeDelete();
            handleChangeDeleteID(id);
          }}
        >
          <Icon source={DeleteMajorMonotone} />
        </Button>
        </Tooltip>
        {!status ? (
          <Tooltip content="Active">
            <Button
              onClick={() => {
                handleChangeActive();
                handleChangeActiveID(id);
              }}
            >
              <Icon source={PlayMajorMonotone} />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip content="Pause">
            <Button
              onClick={() => {
                handleChangeActive();
                handleChangeActiveID(id);
              }}
            >
              <Icon source={PauseMajorMonotone} />
            </Button>
          </Tooltip>
        )}
      </ButtonGroup>,
      ];
    });
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      history.push(tabs[selectedTabIndex].path)
      // setSelected(selectedTabIndex)
    },
    []
  );
  const tabs = [
    {
      id: "catalog-rule",
      content: "Catalog Price Rule",
      path: PATH.discount
    },
    {
      id: "cart-rule",
      content: "Cart Price Rule",
      path: PATH.discountCart
    },
  ];

  return (
    <DefaultLayout pageStatus={status}>
      <Page
        title="Discount"
        primaryAction={{
          content: "Create Discount",
          url: PATH.discountCreate,
        }}
        additionalNavigation={<Avatar size="small" customer />}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <Card.Section>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                    ]}
                    headings={["Name", "Status", "Count", "Total Amount", "Start Date", ""]}
                    rows={_rows || rows}
                  />
                </Card.Section>
              </Tabs>
            </Card>
          </Layout.Section>
        </Layout>
        <Modal
          open={activeDelete}
          onClose={handleChangeDelete}
          secondaryActions={{
            loading: isLoading,
            content: "Cancel",
            onAction: handleChangeDelete,
          }}
          primaryAction={[
            {
              loading: isLoading,
              content: "Confirm",
              onAction: deleteRule,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>Are you sure?</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
        <Modal
          open={active}
          onClose={handleChangeActive}
          secondaryActions={{
            loading: isLoading,
            content: "Cancel",
            onAction: handleChangeActive,
          }}
          primaryAction={[
            {
              loading: isLoading,
              content: "Confirm",
              onAction: activeRule,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>Are you sure?</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </Page>
    </DefaultLayout>
  );
};

Disccount.propTypes = {
  page: PropTypes.object,
};

const mapStateToProps = ({ page, profile, rule }) => ({
  page,
  profile,
  rule,
});

const mapActionToProps = ({ rule }) => ({
  ruleAction: rule,
});

export default connect(mapStateToProps, mapActionToProps)(Disccount);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DefaultLayout from "../../layouts/DefaultLayout";
import {
  Layout,
  Page,
  Card,
  DataTable,
  Avatar,
  Icon,
  Badge,
} from "@shopify/polaris";
import { ExportMinor } from "@shopify/polaris-icons";
import { moneyFormat } from "../../../utils/helpers";
import { concat } from "lodash";
import { CSVLink } from "react-csv";
import moment from "moment";
const Home = ({ page, rule }) => {
  const { status } = page;
  const { rules, cartRules } = rule;
  const _rules = concat(rules, cartRules);
  let rulesCSV = [];
  const filename = `Export-${moment().format('YYYY-MM-DD')}`
  const csvHeader = [
    { label: "ID", key: "id" },
    { label: "Rul Name", key: "name" },
    { label: "Total Applied Time", key: "count" },
    { label: "Total Applied Amount", key: "amount" },
  ];
  const _rows =
    _rules &&
    _rules.map((r) => {
      const { name, status, total_amount, count } = r;
      rulesCSV.push({ name, amount: moneyFormat(total_amount, "${{amount}}"), count });
      return [
        name,
        <Badge status={status ? "success" : ""}>
          {status ? "Active" : "Deactive"}
        </Badge>,
        `${count || 0} used`,
        `${moneyFormat(total_amount, "${{amount}}")}`,
      ];
    });
  return (
    <DefaultLayout pageStatus={status}>
      <Page
        title="Home"
        additionalNavigation={
          <Avatar size="small" initials="AM" customer={false} />
        }
      >
        {rulesCSV && (
          <div className="Polaris-Header-Title__SubTitle custom">
            <CSVLink data={rulesCSV} headers={csvHeader} filename={filename}>
              <span className="Polaris-Navigation__Text">Export</span>
              <Icon source={ExportMinor} />
            </CSVLink>
          </div>
        )}
        <Layout>
          {/* <Layout.Section>
            <Card sectioned>
              <Chart />
            </Card>
          </Layout.Section> */}
          <Layout.Section>
            <Card title="Report Table">
              <DataTable
                columnContentTypes={["text", "text", "text", "text"]}
                headings={["Name", "Status", "Count", "Total Amount"]}
                rows={_rows}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </DefaultLayout>
  );
};

Home.propTypes = {
  page: PropTypes.object,
  rule: PropTypes.object,
};

const mapStateToProps = ({ page, rule }) => ({
  page,
  rule,
});

export default connect(mapStateToProps)(Home);

import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Formik, Form } from "formik";
import FieldComp from "../../Form/Field";
import { connect } from "react-redux";
import { catalogRuleSchema } from "../../../utils/formSchema";
import { PLACEHOLDER_IMAGE } from "../../../constants";
import * as getValue from "lodash.get";
import {
  flatten,
  isEmpty,
  omit,
  set,
  difference,
  concat,
  uniqBy,
} from "lodash";
import Client from "graphql-js-client";
import typeBundle from "../../../types";
import { useHistory } from "react-router-dom";
import { PATH } from "../../../constants";
import moment from "moment-timezone";
import CodesTable from "./codesTable";
import { queryCollection, queryProduct } from "../../../utils/helpers";
import MomentUtils from "@date-io/moment";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Layout,
  Page,
  Card,
  Avatar,
  DatePicker,
  TextStyle,
  Stack,
  Button,
  OptionList,
  InlineError,
  Toast,
  ResourceList,
  ResourceItem,
  Thumbnail,
  ChoiceList,
  TextField,
  Popover,
  Link,
  Tag,
  Modal,
  Select,
  Checkbox,
  Filters,
  Heading,
  Icon
} from "@shopify/polaris";
import {
  DeleteMajorMonotone
} from "@shopify/polaris-icons";
const DiscountDetailCart = ({
  page,
  global,
  profile,
  globalAction,
  rule,
  ruleAction,
  path
}) => {
  const { status } = page;
  const editRule = getValue(rule, "ruleDetail[0]", { quantity: 0 });
  const history = useHistory();

  const today = moment();
  const { storage } = global;
  const optionsList =
    storage && storage.map((s) => ({ value: s.name, label: s.name, id: s.id }));
  const [selectedItems, setSelectedItems] = useState([]);
  const [
    productConditionCurrentIndex,
    setProductConditionCurrentIndex,
  ] = useState();
  const [
    offerItemsCurrentIndex,
    setOfferItemsCurrentIndex,
  ] = useState();
  const [isTotalSpendBundleItems, setIsTotalSpendBundleItems] = useState(false)
  const [posAvailable, setPosAvailable] = useState(true)
  const handleChangePosAvailable = useCallback((value) => setPosAvailable(value), []);
  const [storefrontAvailable, setStorefrontAvailable] = useState(true)
  const handleChangeStorefrontAvailable = useCallback((value) => setStorefrontAvailable(value), []);
  const [maxQuantityDiscount, setMaxQuantityDiscount] = useState("0")
  const handleChangeMaxQuantityDiscount = useCallback((value) => setMaxQuantityDiscount(value), []);
  const [bundleItemsAgreements, setBundleItemsAgreements] = useState(["all"])
  const handleChangeBundleItemsAgreements = useCallback((value) => setBundleItemsAgreements(value), []);
  const [shouldCheckSpendGoal, setShouldCheckSpendGoal] = useState(false)
  const handleChangeShouldCheckSpendGoal = useCallback((value) => setShouldCheckSpendGoal(value), []);
  const [storeDiscountBasedOnProductPrice, setStoreDiscountBasedOnProductPrice] = useState(false)
  const handleChangeStoreDiscountBasedOnProductPrice = useCallback((value) => setStoreDiscountBasedOnProductPrice(value), []);
  const [shouldCheckTotalItems, setShouldCheckTotalItems] = useState(false)
  const handleChangeShouldCheckTotalItems = useCallback((value) => setShouldCheckTotalItems(value), []);
  const handleChangeIsTotalSpendBundleItems = useCallback((value) => setIsTotalSpendBundleItems(value), []);
  const [resourceListLoading, setResourceListLoading] = useState(false);
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [ruleId, setRuleId] = useState(null);
  const [isBasedOnHighestPrice, setIsBasedOnHighestPrice] = useState(0);
  const handleChangeIsBasedOnHighestPrice = useCallback((value) => setIsBasedOnHighestPrice(value), []);
  const [couponQty, setCouponQty] = useState("0");
  const [offerItemQty, setOfferItemQty] = useState("1");
  const [codeLength, setCodeLength] = useState("0");
  const [codePrefix, setCodePrefix] = useState();
  const [codeSuffix, setCodeSuffix] = useState();
  const [usesPerCode, setUsesPerCode] = useState("0");
  const [queryValue, setQueryValue] = useState(null);
  const [startTime, setStartTime] = useState(today.toDate());
  const [endTime, setEndTime] = useState(today.toDate());
  const [canGenerateCoupon, setCanGenerateCoupon] = useState();
  const [isFreeShipping, setisFreeShipping] = useState(false);
  const [includeDiscountPrice, setIncludeDiscountPrice] = useState(false);
  const [ignoreSubsequent, setIgnoreSubsequent] = useState(false);
  const [ignoreSubsequentByItem, setIgnoreSubsequentByItem] = useState(false);
  const [applyCondition, setApplyCondition] = useState();
  const [productCondition, setProductCondition] = useState([]);
  const [offerItems, setOfferItems] = useState([]);
  const handleChangeOfferItems = useCallback(
    (values) => {
      if(typeof(values) === 'number') {
        let temp = [...offerItems];
        if(values > -1) {
          temp.splice(values, 1);
        }
        return setOfferItems(temp);
      } 
      if (isEmpty(values)) {
        const temp = [...offerItems, { quantity: 0 }];
        return setOfferItems(temp);
      } else {
        let temp = [...offerItems];
        let { name, value, idx } = values;
        const _willMergeLine = getValue(temp, `[${idx}][${name}]`, {})
        const _type = getValue(value, "type", "")
        const _apType = getValue(_willMergeLine, "type", "")

        if (_apType !== _type) {
          set(temp, `[${idx}][${name}]`, value);
          return setOfferItems(temp);
        } else {
          let _list = getValue(value, "list", []);
          const _mergeList = _list.map(o => {
            let _arr = getValue(o, "list", []);
            const shop_name = getValue(
              o,
              "shop_name"
            );
            let willMergeList = getValue(
              _willMergeLine,
              "list",
              []
            ).find((o) => o.shop_name === shop_name)
              .list;
            let concatArr = _arr.concat(
              willMergeList
            );
            const uniqconcatArr = uniqBy(
              concatArr,
              "node.id"
            );
            set(o, "list", uniqconcatArr);
            return o;
          });
          set(value, 'list', _mergeList)
          set(temp, `[${idx}][${name}]`, value);
          return setOfferItems(temp);
        }
      }
    },
    [offerItems]
  );
  const [spendGoal, setSpendGoal] = useState("0");
  const [discountAmout, setDiscountAmout] = useState("0");
  const [discountAmoutLimit, setDiscountAmoutLimit] = useState(null);
  const [itemGoal, setItemGoal] = useState("0");
  const [aggregator, setAggregator] = useState(["any"]);
  const [offerAggregator, setOfferAggregator] = useState(["any"]);
  const [priceVariable, setPriceVariable] = useState(["price"]);
  const [selectedApply, setSelectedApply] = useState("fixed_amount");
  const [customerGroupSelected, setcustomerGroupSelected] = useState([]);
  const [popoverStartDateActive, setPopoverStartDateActive] = useState();
  const [popoverEndDateActive, setPopoverEndDateActive] = useState();
  const [popoverEndTimeActive, setPopoverEndTimeActive] = useState();
  const [popoverStartTimeActive, setPopoverStartTimeActive] = useState();
  const [fromDateStr, setFromDateStr] = useState(moment().format("YYYY-MM-DD"));
  const [toDateStr, setToDateStr] = useState(moment().format("YYYY-MM-DD"));
  const [statusSelected, setstatusSelected] = useState([
    getValue(rule, "ruleDetail[0].status", true),
  ]);
  const [activeModal, setActiveModal] = useState();
  const [activeConditionModal, setActiveConditionModal] = useState();
  const [createDiscountError, setCreateDiscountError] = useState("");
  const [createDiscountSuccessfully, setCreateDiscountSuccessfully] = useState(
    ""
  );
  const [storeSelected, setStoreSelected] = useState([]);
  const [customerIncludeTags, setCustomerIncludeTags] = useState("");
  const [customerExcludeTags, setCustomerExcludeTags] = useState("");
  const [condition, setCondition] = useState("");
  const [{ month, year }, setDate] = useState({
    month: today.month(),
    year: today.year(),
  });
  const handleChangeApply = useCallback((value) => setSelectedApply(value), []);
  const handleChangeOfferItemQty = useCallback(
    (value) => setOfferItemQty(value),
    []
  );
  const handleChangeTimezone = useCallback((value) => setTimezone(value), []);
  const handleChangeStartTime = useCallback((value) => setStartTime(value), []);
  const handleChangeEndTime = useCallback((value) => setEndTime(value), []);
  const handleChangeCouponQty = useCallback((value) => setCouponQty(value), []);
  const handleChangeCodeLength = useCallback(
    (value) => setCodeLength(value),
    []
  );
  const handleChangeCodePrefix = useCallback(
    (value) => setCodePrefix(value),
    []
  );
  const handleChangeCodeSuffix = useCallback(
    (value) => setCodeSuffix(value),
    []
  );
  const handleChangeUsesPerCode = useCallback(
    (value) => setUsesPerCode(value),
    []
  );
  const handleChangeCanGenerateCoupon = useCallback(
    (value) => setCanGenerateCoupon(value),
    []
  );
  const handleChangeIgnoreSubsequent = useCallback(
    (value) => setIgnoreSubsequent(value),
    []
  );
  const handleChangeIgnoreSubsequentByItem = useCallback(
    (value) => setIgnoreSubsequentByItem(value),
    []
  );
  const handleChangeIncludeDiscountPrice = useCallback(
    (value) => setIncludeDiscountPrice(value),
    []
  );
  const handleChangeProductConditionCurrentIndex = useCallback(
    (value) => setProductConditionCurrentIndex(value),
    []
  );
  const handleChangeOfferItemsCurrentIndex = useCallback(
    (value) => setOfferItemsCurrentIndex(value),
    []
  );
  const handleQueryValueChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleChangeProductCondition = useCallback(
    (values) => {
      if(typeof(values) === 'number') {
        let temp = [...productCondition];
        if(values > -1) {
          temp.splice(values, 1);
        }
        return setProductCondition(temp);
      } 
      if (isEmpty(values)) {
        const temp = [...productCondition, { quantity: 0 }];
        return setProductCondition(temp);
      } else {
        let temp = [...productCondition];
        let { name, value, idx } = values;
        const _willMergeLine = getValue(temp, `[${idx}][${name}]`, {})
        const _type = getValue(value, "type", "")
        const _apType = getValue(_willMergeLine, "type", "")

        if (_apType !== _type) {
          set(temp, `[${idx}][${name}]`, value);
          return setProductCondition(temp);
        } else {
          let _list = getValue(value, "list", []);
          const _mergeList = _list.map(o => {
            let _arr = getValue(o, "list", []);
            const shop_name = getValue(
              o,
              "shop_name"
            );
            let willMergeList = getValue(
              _willMergeLine,
              "list",
              []
            ).find((o) => o.shop_name === shop_name)
              .list;
            let concatArr = _arr.concat(
              willMergeList
            );
            const uniqconcatArr = uniqBy(
              concatArr,
              "node.id"
            );
            set(o, "list", uniqconcatArr);
            return o;
          });
          set(value, 'list', _mergeList)
          set(temp, `[${idx}][${name}]`, value);
          return setProductCondition(temp);
        }
      }
    },
    [productCondition]
  );
  const handleChangeFreeShipping = useCallback(
    (newChecked) => setisFreeShipping(newChecked),
    []
  );
  const handleChangeSpendGoal = useCallback((value) => setSpendGoal(value), []);
  const handleChangeDiscountAmout = useCallback(
    (value) => setDiscountAmout(value),
    []
  );
  const handleChangeItemGoal = useCallback((value) => setItemGoal(value), []);
  const handleChangeAggregator = useCallback(
    (value) => setAggregator(value),
    []
  );
  const handleChangeOfferAggregator = useCallback(
    (value) => setOfferAggregator(value),
    []
  );
  const handleChangePriceVariable = useCallback(
    (value) => setPriceVariable(value),
    []
  );
  const toggleModal = useCallback(() => setActiveModal(!activeModal), [
    activeModal,
  ]);
  const toggleConditionModal = useCallback(() => setActiveConditionModal(!activeConditionModal), [
    activeConditionModal,
  ]);
  const [selectedDates, setSelectedDates] = useState({
    start: today.toDate(),
    end: today.toDate(),
  });
  const [{ toMonth, toYear }, setToDate] = useState({
    toMonth: today.month(),
    toYear: today.year(),
  });

  const [selectedToDates, setSelectedToDates] = useState({
    start: moment(selectedDates["end"]).toDate(),
    end: moment(selectedDates["end"]).toDate(),
  });
  const handleStoreChange = useCallback((value) => setStoreSelected(value), []);
  const handleChangeCustomerGroup = useCallback(
    (value) => setcustomerGroupSelected(value),
    []
  );
  const togglePopoverStartDateActive = useCallback(
    () => setPopoverStartDateActive((value) => !value),
    []
  );
  const togglePopoverEndTimeActive = useCallback(
    () => setPopoverEndTimeActive((value) => !value),
    []
  );
  const togglePopoverStartTimeActive = useCallback(
    () => setPopoverStartTimeActive((value) => !value),
    []
  );
  const togglePopoverEndDateActive = useCallback(
    () => setPopoverEndDateActive((value) => !value),
    []
  );
  const handleChangeCustomerIncludeTags = useCallback(
    (value) => setCustomerIncludeTags(value),
    []
  );
  const handleChangeCustomerExcludeTags = useCallback(
    (value) => setCustomerExcludeTags(value),
    []
  );
  const handleStatusChange = useCallback(
    (value) => setstatusSelected(value),
    []
  );
  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );
  const handleToMonthChange = useCallback(
    (toMonth, toYear) => setToDate({ toMonth, toYear }),
    []
  );
  const statusOptions = [
    { label: "Active", value: true },
    { label: "Deactive", value: false },
  ];
  const renderCustomerIncludeTagsInput = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelText="Choose customers with specific tags"
          onChange={handleChangeCustomerIncludeTags}
          value={customerIncludeTags}
          name="customer_include_tags"
          placeholder="Comma separated list of tags"
          type="text"
        />
      ),
    [handleChangeCustomerIncludeTags, customerIncludeTags]
  );
  const renderCustomerExcludeTagsInput = useCallback(
    (isSelected) =>
      isSelected && (
        <TextField
          labelText="Choose customers with specific tags"
          onChange={handleChangeCustomerExcludeTags}
          value={customerExcludeTags}
          name="customer_exclude_tags"
          placeholder="Comma separated list of tags"
          type="text"
        />
      ),
    [handleChangeCustomerExcludeTags, customerExcludeTags]
  );
  const customerListItem = [
    {
      label: "Only show to signed in customers",
      value: "customer_include_tags",
      renderChildren: renderCustomerIncludeTagsInput,
    },
    {
      label: "Exclude signed in customers with specific tags",
      value: "customer_exclude_tags",
      renderChildren: renderCustomerExcludeTagsInput,
    },
  ];

  const applyListItem = [
    { label: "Apply as fixed amount of original", value: "fixed_amount" },
    { label: "Apply as percentage of original", value: "percentage" },
  ];
  let initiaRulelValues = path === PATH.discountCartDetail ? getValue(rule, "ruleDetail[0]", {
    name: editRule.name || "",
    description: editRule.description || "",
    status: editRule.status || false,
    stores: editRule.stores || [],
    priority: editRule.priority || 0,
    condition: {},
    apply: editRule.apply || applyListItem[0].value,
    discount_amount: editRule.discount_amount || 0,
    discount_amount_limit: editRule.discount_amount_limit || 0,
    customer_groups: editRule.customer_groups || customerListItem[0].value,
  }) : {
    name: "",
    description: "",
    status:  false,
    stores: [],
    priority: 0,
    condition: {},
    apply: applyListItem[0].value,
    discount_amount: 0,
    discount_amount_limi: 0,
    customer_groups: customerListItem[0].value,
  };
  const toggleToast = useCallback(
    () => setCreateDiscountSuccessfully((active) => !active),
    []
  );
  const toastMarkup = createDiscountSuccessfully ? (
    <Toast content="Successfully" onDismiss={toggleToast} duration={2000} />
  ) : null;
  const handleFiltersClearAll =  useCallback(() => {
    handleQueryValueChange('');
  }, []);
  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      onQueryChange={(queryValue) => {
        handleQueryValueChange(queryValue);
      }}
      onQueryClear={handleFiltersClearAll}
    >
      <div style={{ paddingLeft: "8px" }}>
        <Button
          onClick={() => {
            setSelectedItems([]);
            fetchProducts(false, false, queryValue);
          }}
        >
          Apply
        </Button>
      </div>
    </Filters>
  );
  const productListMakrup = (clear, filter) => {
    if (clear) {
      return [];
    }
    let arr =
      !isEmpty(condition) && !isEmpty(global[condition]) && 
      global[condition].map((item, index) => {
        const list = getValue(item, "list");
        const { shop_name } = item;
        if(isEmpty(list)) {
          return {};
        }
        return !isEmpty(list) && list.map((l, idx) => {
          const {
            node: { title, images, id, image },
          } = l;
          return {
            title,
            images,
            id: `[${index}].list[${idx}]`,
            image,
            shop_name,
            node_id: id,
          };
        });
      });
    arr = flatten(arr);
    return arr;
  };
  const renderItem = (item) => {
    const { id, title, image, images, shop_name } = item;
    const source =
      condition === "products"
        ? getValue(image, "src", PLACEHOLDER_IMAGE)
        : getValue(images, "edges[0].node.src", PLACEHOLDER_IMAGE);
    const media = <Thumbnail source={source} alt={title}></Thumbnail>;

    return (
      <ResourceItem
        id={id}
        title={title}
        media={media}
        shop_name={shop_name}
        key={`key-resouce-${id}`}
      >
        <h3>
          <TextStyle variation="strong">{title}</TextStyle>
        </h3>
        Store: {shop_name}
      </ResourceItem>
    );
  };

  const onSubmitForm = async (values, actions) => {
    let data = Object.assign({}, values);
    const _startD = moment(fromDateStr);
    const _endD = moment(toDateStr);
    const _startT = moment(startTime, "HH:mm");
    const _endT = moment(endTime, "HH:mm");
    if (
      _endD.isBefore(_startD) ||
      (_endD.isSame(_startD) && _endT.isSameOrBefore(_startT))
    ) {
      setCreateDiscountError("End date time is not valid!");
      return;
    }
    set(data, "offer_items_quantity", offerItemQty);
    set(data, "store_discount_based_on_the_product_price", storeDiscountBasedOnProductPrice);
    set(data, "pos_available", posAvailable);
    set(data, "storefront_available", storefrontAvailable);
    set(data, "offer_items_aggreator", offerAggregator[0]);
    set(data, "price_variable", priceVariable[0]);
    set(data, "bundle_items_agreeator", bundleItemsAgreements[0]);
    set(data, "total_spend_of_bundle_items", isTotalSpendBundleItems);
    set(data, "should_check_spend_goal", shouldCheckSpendGoal);
    set(data, "should_check_total_items", shouldCheckTotalItems);
    set(data, "max_quantity_discount", maxQuantityDiscount);
    set(
      data,
      "from",
      moment
        .tz(fromDateStr, timezone)
        .set({
          hours: _startT.hours(),
          minutes: _startT.minutes(),
        })
        .format()
    );
    set(
      data,
      "to",
      moment
        .tz(toDateStr, timezone)
        .set({
          hours: _endT.hours(),
          minutes: _endT.minutes(),
        })
        .format()
    );

    set(data, "toTime", moment(endTime, "HH:mm").format("HH:mm:ss.SSS"));
    set(data, "fromTime", moment(startTime, "HH:mm").format("HH:mm:ss.SSS"));
    set(
      data,
      "condition",
      getValue(
        rule,
        storage.filter((s) => storeSelected)
      )
    );
    set(
      data,
      "stores",
      storage.filter((s) => storeSelected.includes(s.name))
    );
    set(data, "status", statusSelected);
    set(data, "apply", selectedApply);
    set(data, "ignore_subsequent", ignoreSubsequent);
    set(data, "include_discount_price", includeDiscountPrice);
    set(data, "has_free_shipping", isFreeShipping);
    set(data, "customer_include_tags", customerIncludeTags);
    set(data, "customer_exclude_tags", customerExcludeTags);
    set(
      data,
      "only_signed_in",
      customerGroupSelected.includes("customer_include_tags")
    );
    set(data, "timezone", timezone);
    set(data, "condition.line_items", productCondition);
    set(data, "condition.offer_items", offerItems);
    set(data, "aggregator", aggregator[0]);
    if(shouldCheckTotalItems) {
      set(data, "total_item_goal", itemGoal);
    }
    if(shouldCheckSpendGoal) {
      set(data, "total_spend_goal", spendGoal);
    }
    if(selectedApply === 'fixed_amount') {
      set(data, "discount_amount", discountAmout * 100);
    } else {
      set(data, "discount_amount", discountAmout);
    }
    set(data, "discount_amount_limit", discountAmoutLimit * 100);
    set(data, "can_generate_coupon", canGenerateCoupon);
    set(data, "code_length", codeLength);
    set(data, "code_prefix", codePrefix);
    set(data, "code_suffix", codeSuffix);
    set(data, "coupon_qty", couponQty);
    set(data, "uses_per_code", usesPerCode);
    set(data, "ignore_subsequent_by_item", ignoreSubsequentByItem);
    set(data, "only_highest_price_product", isBasedOnHighestPrice);
    data = omit(data, ["created_at", "updated_at", "coupon_codes"]);
    let _data, error;
    let id = data.id;

    if (PATH.discountCartDetail === path) {
      id = data.id;
      _data = await ruleAction.updateCartRule({ id, values: data });
    } else {
      data = omit(data, ["id"]);
      const user_id = getValue(profile, "user.id");
      set(data, "user", { id: user_id });
      _data = await ruleAction.createCartRule(data);
      id = _data.id
    }
    if (canGenerateCoupon && id) {
      _data = await ruleAction.createCodes({...data, id});
    }
    if (_data && _data.error) {
      setCreateDiscountError(_data.error);
      await ruleAction.fetchCartRuleEdit({query:`id=${id}`})
    } else {
      setCreateDiscountSuccessfully(true);
      history.push(PATH.discountCart);
    }
  };
  const fetchProducts = (isNext = false, isClear = false, hasQuery) => {
    if (isClear) {
      globalAction.setProducts([]);
      globalAction.setCollections([]);
      globalAction.setNodeProducts([]);
      productListMakrup();
      return;
    }
    let products = [];
    let collections = [];
    let nodeProducts = [];
    const fetchFnc = async () => {
      if (isNext) {
        const _nodeProducts = getValue(global, "node_products", []);
        const canFetch = getValue(_nodeProducts, "[0].list");
        if (isEmpty(canFetch)) {
          return;
        }
        setResourceListLoading(true);
        Promise.all(
          _nodeProducts.map(async (nodeItem) => {
            const { client, list } = nodeItem;
            return client.fetchNextPage(list);
          })
        ).then((values) => {
          values.map((v, index) => {
            const _client = _nodeProducts[index].client;
            const name = _nodeProducts[index].shop_name;
            const c = getValue(v, "data.shop.collections.edges", []);
            const p = flatten(
              getValue(v, "data.shop.products.edges", []).map((product) => {
                const productTitle = getValue(product, "node.title", "");
                return getValue(product, "node.variants.edges", []).map(
                  (variant) => {
                    return {
                      ...variant,
                      node: {
                        ...variant.node,
                        title: `${productTitle} - ${variant.node.title}`,
                      },
                    };
                  }
                );
              }, [])
            );
            products.push({ shop_name: name, list: p });
            collections.push({ shop_name: name, list: c });
            nodeProducts.push({
              shop_name: name,
              list: getValue(v, "model", []),
              client: _client,
            });
          });
          globalAction.setProducts(
            concat(getValue(global, "products", []), products)
          );
          globalAction.setCollections(
            concat(getValue(global, "collections", []), collections)
          );
          globalAction.setNodeProducts(nodeProducts);
          productListMakrup();
          setResourceListLoading(false);
        });
        return;
      }

      setResourceListLoading(true);
      const clients = storeSelected.map((store) => {
        const _name = getValue(store, "name", "");
        const filtedStore = storage.find((s) => {
          if (typeof s !== "object") {
            return false;
          }
          const storeName = getValue(s, "name", "");
          return typeof store === "string"
            ? store.localeCompare(storeName) === 0
            : _name.localeCompare(storeName) === 0;
        });
        const { name, access_token } = filtedStore;
        return {
          client: new Client(typeBundle, {
            url: `https://${name}/api/graphql`,
            fetcherOptions: {
              headers: {
                "X-Shopify-Storefront-Access-Token": access_token,
              },
            },
          }),
          name,
        };
      });
      const _querry = hasQuery ? `title:*${queryValue}*` : "";
      Promise.all(
        clients.map(async (client) => {
          const query = client.client.query((root) => {
            root.add("shop", (shop) => {
              shop.add("name");
              if(condition === 'products') {
                queryProduct(shop, _querry);
              }
              if(condition === 'collections') {
                queryCollection(shop, _querry);
              }
            });
          });
          return client.client.send(query);
        })
      ).then((values) => {
        values.map((v, index) => {
          const _client = clients[index];
          const name = clients[index].name;
          const c = getValue(v, "data.shop.collections.edges");
          const p = flatten(
            getValue(v, "data.shop.products.edges", []).map((product) => {
              const productTitle = getValue(product, "node.title", "");
              return getValue(product, "node.variants.edges", []).map(
                (variant) => {
                  return {
                    ...variant,
                    node: {
                      ...variant.node,
                      title: `${productTitle} - ${variant.node.title}`,
                    },
                  };
                }
              );
            }, [])
          );
          products.push({ shop_name: name, list: p });
          collections.push({ shop_name: name, list: c });
          nodeProducts.push({
            shop_name: name,
            list: v.model.shop.products,
            client: _client.client,
          });
        });
        globalAction.setProducts(products);
        globalAction.setCollections(collections);
        globalAction.setNodeProducts(nodeProducts);
        productListMakrup();
        setResourceListLoading(false);
      });
    };
    fetchFnc();
  };
  useEffect(fetchProducts, [condition]);
  useEffect(() => {
    if (!isEmpty(editRule) && path !== PATH.discountCreateCart) {
      const {
        id,
        ignore_subsequent_by_item,
        customer_exclude_tags,
        customer_include_tags,
        status,
        from,
        to,
        only_signed_in,
        total_spend_goal,
        total_item_goal,
        discount_amount,
        apply,
        aggregator,
        has_free_shipping,
        ignore_subsequent,
        include_discount_price,
        fromTime,
        toTime,
        offer_items_quantity,
        offer_items_aggreator,
        only_highest_price_product,
        total_spend_of_bundle_items,
        bundle_items_agreeator,
        pos_available,
        storefront_available,
        should_check_spend_goal,
        should_check_total_items,
        store_discount_based_on_the_product_price,
        max_quantity_discount,
        discount_amount_limit,
        price_variable
      } = editRule;
      if (ruleId !== id && PATH.discountCartDetail === path) {
        setRuleId(id);
        const _timezone = getValue(editRule, "timezone");
        const stores = getValue(editRule, "stores", []);
        const _aggregator = getValue(editRule, "aggregator", "");
        ruleAction.setState({
          ...rule,
          rules: [
            {
              ...editRule,
              from: moment(from).format(),
              to: moment(to).format(),
            },
          ],
        });
        if (_timezone !== timezone && _timezone) {
          setTimezone(_timezone);
        }

        const _from = moment(from).tz(_timezone || "etc/UTC");
        const _to = moment(to).tz(_timezone || "etc/UTC");
        if(posAvailable !== pos_available) {
          setPosAvailable(pos_available)
        }
        if(storefront_available !== storefrontAvailable) {
          setStorefrontAvailable(storefront_available)
        }
        if(maxQuantityDiscount !== String(max_quantity_discount)) {
          setMaxQuantityDiscount(String(max_quantity_discount))
        }
        if (offer_items_quantity !== offerItemQty && offer_items_quantity) {
          setOfferItemQty(String(offer_items_quantity));
        }
        if(should_check_spend_goal !== shouldCheckSpendGoal) {
          setShouldCheckSpendGoal(should_check_spend_goal)
        }
        if(should_check_total_items !== shouldCheckTotalItems) {
          setShouldCheckTotalItems(should_check_total_items)
        }
        if(total_spend_of_bundle_items !== isTotalSpendBundleItems) {
          setIsTotalSpendBundleItems(total_spend_of_bundle_items)
        }
        if (fromTime !== startTime && fromTime) {
          setStartTime(moment(fromTime, "HH:mm").toDate());
        }
        if(storeDiscountBasedOnProductPrice !== store_discount_based_on_the_product_price) {
          setStoreDiscountBasedOnProductPrice(store_discount_based_on_the_product_price)
        }
        if (endTime !== toTime && toTime) {
          setEndTime(moment(toTime, "HH:mm").toDate());
        }
        if (fromDateStr !== from) {
          setFromDateStr(_from.format("YYYY-MM-DD"));
        }
        if (toDateStr !== to) {
          setToDateStr(_to.format("YYYY-MM-DD"));
        }

        if (difference([status], statusSelected)) {
          setstatusSelected([status], statusSelected);
        }
        if (apply !== selectedApply) {
          handleChangeApply(apply);
        }
        if (ignore_subsequent !== ignoreSubsequent) {
          setIgnoreSubsequent(ignore_subsequent);
        }
        if (ignore_subsequent_by_item !== ignoreSubsequentByItem) {
          setIgnoreSubsequentByItem(ignore_subsequent_by_item);
        }
        if (only_highest_price_product !== isBasedOnHighestPrice) {
          setIsBasedOnHighestPrice(only_highest_price_product);
        }
        if (include_discount_price !== includeDiscountPrice) {
          setIncludeDiscountPrice(include_discount_price);
        }
        if (has_free_shipping !== isFreeShipping) {
          setisFreeShipping(has_free_shipping);
        }
        setcustomerGroupSelected([
          only_signed_in && "customer_include_tags",
          customer_exclude_tags && "customer_exclude_tags",
        ]);
        if (customer_exclude_tags !== customerExcludeTags) {
          setCustomerExcludeTags(customer_exclude_tags);
        }
        if (customerIncludeTags !== customer_include_tags) {
          setCustomerIncludeTags(customer_include_tags);
        }
        if (total_spend_goal !== spendGoal) {
          setSpendGoal(total_spend_goal);
        }

        if (String(total_item_goal) !== itemGoal) {
          setItemGoal(String(total_item_goal));
        }

        if (String(discount_amount) !== discountAmout) {
          if(apply === 'fixed_amount') {
            handleChangeDiscountAmout(String(discount_amount / 100));
          } else {
            handleChangeDiscountAmout(String(discount_amount));
          }
        }
        if (String(discount_amount_limit) !== discountAmoutLimit) {
          setDiscountAmoutLimit(String(discount_amount_limit / 100));
        }
        if (aggregator && difference([_aggregator], aggregator)) {
          setAggregator([aggregator ? aggregator : "all"]);
        }
        if (
          offer_items_aggreator &&
          difference([offer_items_aggreator], offerAggregator)
        ) {
          setOfferAggregator([
            offer_items_aggreator ? offer_items_aggreator : "all",
          ]);
        }
        if (
          offer_items_aggreator &&
          difference([price_variable], priceVariable)
        ) {
          setPriceVariable([
            price_variable ? price_variable : "price",
          ]);
        }
        if (
          bundle_items_agreeator &&
          difference([bundle_items_agreeator], bundleItemsAgreements)
        ) {
          setBundleItemsAgreements([
            bundle_items_agreeator ? bundle_items_agreeator : "all",
          ]);
        }
        setProductCondition(getValue(editRule, "condition.line_items", []));
        setOfferItems(getValue(editRule, "condition.offer_items"));
        setStoreSelected(stores.map((store) => store.name));
      }
    }
  }, [rule]);
  const optionListMarkup = optionsList.length ? (
    <Card>
      <OptionList
        allowMultiple
        title="Stores"
        options={optionsList}
        selected={storeSelected}
        onChange={handleStoreChange}
      />
    </Card>
  ) : null;

  return (
    <DefaultLayout pageStatus={status}>
      <Page
        title={
          path === PATH.discountCreateCart
            ? "Create Discount"
            : `${getValue(editRule, "name", "")}`
        }
        additionalNavigation={
          <Avatar size="small" initials="AM" customer={false} />
        }
      >
        {toastMarkup}
        <Layout>
          <Layout.Section>
            <Formik
              initialValues={initiaRulelValues}
              validationSchema={catalogRuleSchema}
              onSubmit={onSubmitForm}
            >
              {({ isValid, isSubmitting }) =>
                editRule && (
                  <Form>
                    <Button
                      primary
                      submit
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Save
                    </Button>
                    {createDiscountError && (
                      <InlineError message={createDiscountError} />
                    )}
                    <Layout.AnnotatedSection title="Discount Information">
                      <Card>
                        <Card.Section>
                          <FieldComp labelText="Rule name" name="name" />
                        </Card.Section>
                        <Card.Section>
                          <FieldComp
                            labelText="Description"
                            name="description"
                            as="textarea"
                          />
                        </Card.Section>
                        <Card.Section>
                          <ChoiceList
                            title="Status"
                            name="status"
                            choices={statusOptions}
                            selected={statusSelected}
                            onChange={handleStatusChange}
                          ></ChoiceList>
                        </Card.Section>
                        <Card.Section>
                          <Checkbox
                            name="can_generate_coupon"
                            label="Generate coupon"
                            checked={canGenerateCoupon}
                            onChange={handleChangeCanGenerateCoupon}
                          ></Checkbox>
                        </Card.Section>
                      </Card>
                    </Layout.AnnotatedSection>
                    {canGenerateCoupon ? (
                      <Layout.AnnotatedSection title="Manage Coupon Codes">
                        <Card>
                          <Card.Section>
                            <TextField
                              name="coupon_qty"
                              label="Coupon Qty"
                              type="number"
                              min="0"
                              value={couponQty}
                              onChange={handleChangeCouponQty}
                            ></TextField>
                          </Card.Section>
                          <Card.Section>
                            <TextField
                              name="code_length"
                              label="Code Length"
                              type="number"
                              max="10"
                              value={codeLength}
                              onChange={handleChangeCodeLength}
                            ></TextField>
                            <div style={{ marginTop: "2rem" }}>
                              <TextStyle variation="subdued">
                                *Excluding suffix and separators.
                              </TextStyle>
                            </div>
                          </Card.Section>
                          <Card.Section>
                            <TextField
                              name="code_prefix"
                              label="Code Prefix"
                              value={codePrefix}
                              onChange={handleChangeCodePrefix}
                            ></TextField>
                          </Card.Section>
                          <Card.Section>
                            <TextField
                              name="code_suffix"
                              label="Code Suffix"
                              value={codeSuffix}
                              onChange={handleChangeCodeSuffix}
                            ></TextField>
                          </Card.Section>
                          <Card.Section>
                            <TextField
                              name="code_uses_per_code"
                              label="Uses per code"
                              value={usesPerCode}
                              type="number"
                              onChange={handleChangeUsesPerCode}
                              min="0"
                            ></TextField>
                          </Card.Section>
                        </Card>
                      </Layout.AnnotatedSection>
                    ) : null}
                    <Layout.AnnotatedSection title="Availability">
                      <Card>
                        <Card.Section>{optionListMarkup}</Card.Section>
                        <Card.Section>
                          <ChoiceList
                            title="Customer Group"
                            choices={customerListItem}
                            selected={customerGroupSelected}
                            onChange={handleChangeCustomerGroup}
                            allowMultiple={true}
                          />
                        </Card.Section>
                        <Card.Section>
                          <Select
                            label="Time Zones"
                            options={moment.tz.names()}
                            value={timezone}
                            onChange={handleChangeTimezone}
                          />
                        </Card.Section>
                        <Card.Section>
                          <div className="ApplicabilityDate__Row">
                            <Stack>
                              <Popover
                                active={popoverStartDateActive}
                                onClose={togglePopoverStartDateActive}
                                ariaHaspopup={false}
                                sectioned
                                activator={
                                  <div onClick={togglePopoverStartDateActive}>
                                    <FieldComp
                                      name="from"
                                      type="text"
                                      labelText="Start Date"
                                      readOnly={true}
                                      value={fromDateStr}
                                    />
                                  </div>
                                }
                              >
                                <DatePicker
                                  month={month}
                                  year={year}
                                  onChange={(date) => {
                                    setSelectedDates(date);
                                    ruleAction.setState({
                                      ...rule,
                                      rules: [
                                        {
                                          ...editRule,
                                          from: moment(date.start).format(),
                                        },
                                      ],
                                    });
                                    setFromDateStr(
                                      moment(date.start).format("YYYY-MM-DD")
                                    );
                                    setPopoverStartDateActive(false);
                                  }}
                                  onMonthChange={handleMonthChange}
                                  selected={selectedDates}
                                  allowRange={false}
                                  // disableDatesBefore={moment()
                                  //   .subtract(1, "days")
                                  //   .toDate()}
                                ></DatePicker>
                              </Popover>
                              <div className="time-group">
                                <Popover
                                  ariaHaspopup={false}
                                  sectioned
                                  fluidContent={true}
                                  active={popoverStartTimeActive}
                                  onClose={togglePopoverStartTimeActive}
                                  activator={
                                    <div onClick={togglePopoverStartTimeActive}>
                                      <TextField
                                        labelHidden
                                        type="text"
                                        placeholder="HH:MM"
                                        name="start_time"
                                        value={moment(startTime).format(
                                          "HH:mm"
                                        )}
                                        readOnly
                                      />
                                    </div>
                                  }
                                >
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <TimePicker
                                      onChange={handleChangeStartTime}
                                      variant="static"
                                      value={startTime}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Popover>
                              </div>
                            </Stack>
                          </div>
                        </Card.Section>
                        <Card.Section>
                          <div className="ApplicabilityDate__Row">
                            <Stack>
                              <Popover
                                active={popoverEndDateActive}
                                onClose={togglePopoverEndDateActive}
                                ariaHaspopup={false}
                                sectioned
                                activator={
                                  <div onClick={togglePopoverEndDateActive}>
                                    <FieldComp
                                      name="to"
                                      type="text"
                                      labelText="End Date"
                                      readOnly={true}
                                      value={toDateStr}
                                    />
                                  </div>
                                }
                              >
                                <DatePicker
                                  month={toMonth}
                                  year={toYear}
                                  onChange={(date) => {
                                    setSelectedToDates(date);
                                    ruleAction.setState({
                                      ...rule,
                                      rules: [
                                        {
                                          ...editRule,
                                          to: moment(date.start).format(),
                                        },
                                      ],
                                    });
                                    setToDateStr(
                                      moment(date.start).format("YYYY-MM-DD")
                                    );
                                    setPopoverStartDateActive(false);
                                  }}
                                  onMonthChange={handleToMonthChange}
                                  selected={selectedToDates}
                                  allowRange={false}
                                  // disableDatesBefore={moment()
                                  //   .subtract(1, "days")
                                  //   .toDate()}
                                ></DatePicker>
                              </Popover>
                              <div className="time-group">
                                <Popover
                                  ariaHaspopup={false}
                                  sectioned
                                  fluidContent={true}
                                  active={popoverEndTimeActive}
                                  onClose={togglePopoverEndTimeActive}
                                  activator={
                                    <div onClick={togglePopoverEndTimeActive}>
                                      <TextField
                                        labelHidden
                                        type="text"
                                        name="end_time"
                                        placeholder="HH:mm"
                                        value={moment(endTime).format("HH:mm")}
                                        readOnly={true}
                                      />
                                    </div>
                                  }
                                >
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <TimePicker
                                      onChange={handleChangeEndTime}
                                      variant="static"
                                      value={endTime}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Popover>
                              </div>
                            </Stack>
                          </div>
                        </Card.Section>
                        <Card.Section>
                          <FieldComp
                            labelText="Priority"
                            name="priority"
                            type="number"
                            min="0"
                            max="1000"
                          />
                          <TextStyle variation="subdued">
                            *(0-1000 : 0 = Highest , 1000 = Lowest)
                          </TextStyle>
                        </Card.Section>
                        <Card.Section>
                          <Checkbox
                            name="storefront_available"
                            checked={storefrontAvailable}
                            label="Available on online store"
                            onChange={handleChangeStorefrontAvailable}
                          />
                        </Card.Section>
                        <Card.Section>
                          <Checkbox
                            name="pos_available"
                            checked={posAvailable}
                            label="Available on pos"
                            onChange={handleChangePosAvailable}
                          />
                        </Card.Section>
                        <Card.Section>
                          <Checkbox
                            name="is_ignore_subsequent"
                            checked={ignoreSubsequent}
                            label="Ignore subsequent rules"
                            onChange={handleChangeIgnoreSubsequent}
                          />
                        </Card.Section>
                        <Card.Section>
                          <Checkbox
                            name="is_ignore_subsequent_by_item"
                            checked={ignoreSubsequentByItem}
                            label="Ignore subsequent rules by item"
                            onChange={handleChangeIgnoreSubsequentByItem}
                          />
                        </Card.Section>
                        <Card.Section>
                          <Checkbox
                            name="isBasedOnHighestPrice"
                            checked={isBasedOnHighestPrice}
                            label="Discount on the highest price product"
                            onChange={handleChangeIsBasedOnHighestPrice}
                          />
                        </Card.Section>
                        <Card.Section>
                          <Checkbox
                            name="include_discount_price"
                            checked={includeDiscountPrice}
                            label="Calculate the discount based on discounted price"
                            onChange={handleChangeIncludeDiscountPrice}
                          />
                        </Card.Section>
                      </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection title="Condition">
                      <Card>
                        <Card.Section title="Condition Aggregator">
                          <ChoiceList
                            choices={[
                              { label: "Any of the conditions below applied, trigger the discount", value: "any" },
                              { label: "All of the conditions below applied, trigger the discount", value: "all" },
                            ]}
                            selected={aggregator}
                            onChange={handleChangeAggregator}
                          ></ChoiceList>
                        </Card.Section>
                        <Card.Section title="Calculation based on price variable">
                          <ChoiceList
                            choices={[
                              { label: "Price", value: "price" },
                              { label: "Compare at price", value: "compare_at_price" },
                            ]}
                            selected={priceVariable}
                            onChange={handleChangePriceVariable}
                          ></ChoiceList>
                        </Card.Section>
                        <Card.Section>
                          <Stack>
                            <div className="checkbox-wrapper-in-stack">

                              <Checkbox
                                name="should_check_spend_goal"
                                checked={shouldCheckSpendGoal}
                                label="Enable spend goal"
                                onChange={handleChangeShouldCheckSpendGoal}
                              />
                            </div>
                          <TextField
                            label="Total Spend Goal"
                            type="number"
                            name="total_spend_goal"
                            value={spendGoal}
                            onChange={handleChangeSpendGoal}
                            min="0"
                            disabled={!shouldCheckSpendGoal}
                          ></TextField>
                          </Stack>
                        </Card.Section>
                        <Card.Section>
                          <Checkbox
                            name="isTotalSpendBundleItems"
                            checked={isTotalSpendBundleItems}
                            label="Calculate the total spend goal based on the bundle item price"
                            onChange={handleChangeIsTotalSpendBundleItems}
                          />
                        </Card.Section>
                        <Card.Section>
                          <Stack>

                        <div className="checkbox-wrapper-in-stack">

                            <Checkbox
                              name="should_check_total_items"
                              checked={shouldCheckTotalItems}
                              label="Enable total items goal"
                              onChange={handleChangeShouldCheckTotalItems}
                            />
                        </div>
                            <TextField
                              label="Total Items Goal"
                              type="number"
                              name="total_item_goal"
                              value={itemGoal}
                              onChange={handleChangeItemGoal}
                              min="0"
                              disabled={!shouldCheckTotalItems}
                            ></TextField>
                          </Stack>
                        </Card.Section>
                        <Card.Section title="Bundle Items aggregator">
                          <ChoiceList
                            choices={[
                              { label: "One of the items below must be in the cart to trigger the discount", value: "any" },
                              { label: "All of the items below must be in the cart to trigger the discount", value: "all" },
                            ]}
                            selected={bundleItemsAgreements}
                            onChange={handleChangeBundleItemsAgreements}
                          ></ChoiceList>
                        </Card.Section>
                        <Card.Section title="Bundle Items">
                          <div className="stack__condition">
                            {productCondition &&
                              productCondition.map((condition, idx) => {
                                let list = getValue(
                                  condition,
                                  "condition.list",
                                  []
                                );
                                return (
                                  <>
                                    <Stack key={`condition-product-${idx}`}>
                                      <TextField
                                        name={`product_condition[${idx}][quantity]`}
                                        type="number"
                                        prefix="Quantity"
                                        value={condition.quantity || "0"}
                                        min="1"
                                        onChange={(value) => {
                                          handleChangeProductCondition({
                                            name: "quantity",
                                            value,
                                            idx,
                                          });
                                        }}
                                      />
                                      <Button
                                        onClick={() => {
                                          setQueryValue("");
                                          setSelectedItems([]);
                                          setCondition("products");
                                          handleChangeProductConditionCurrentIndex(
                                            idx
                                          );
                                          toggleConditionModal();
                                        }}
                                      >
                                        Choose Products
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          setQueryValue("");
                                          setSelectedItems([]);
                                          handleChangeProductConditionCurrentIndex(
                                            idx
                                          );
                                          setCondition("collections");
                                          toggleConditionModal();
                                        }}
                                      >
                                        Choose Collections
                                      </Button>
                                      <Button
                                      onClick={() => {
                                        handleChangeProductCondition(idx);
                                      }}
                                      >
                                        <Icon source={DeleteMajorMonotone}/>
                                      </Button>
                                    </Stack>

                                    {!isEmpty(list) ? (
                                      <div className="InventoryTags">
                                        {list.map((item, ix) => {
                                          let shop_name = getValue(
                                            item,
                                            "shop_name",
                                            ""
                                          ).split(".")[0];
                                          const item_list = getValue(
                                            item,
                                            "list"
                                          );
                                          return (
                                            item_list &&
                                            item_list.map((_item, index) => {
                                              const title = getValue(
                                                _item,
                                                "node.title"
                                              );
                                              return title ? (
                                                <Tag
                                                  onRemove={() => {
                                                    let _condition = {
                                                      ...condition,
                                                    };
                                                    let _list = [...item_list];
                                                    _list.splice(index, 1);
                                                    set(
                                                      _condition,
                                                      `condition.list[${ix}][list]`,
                                                      _list
                                                    );
                                                    let _productCondition = [
                                                      ...productCondition,
                                                    ];
                                                    set(
                                                      _productCondition,
                                                      `[${idx}]`,
                                                      _condition
                                                    );
                                                    setProductCondition(
                                                      _productCondition
                                                    );
                                                  }}
                                                  key={shop_name + "-" + title}
                                                >
                                                  <strong>
                                                    {shop_name} :{" "}
                                                  </strong>
                                                  {title}
                                                </Tag>
                                              ) : null;
                                            })
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })}
                          </div>
                          <div className="bottom-action">
                            <Link
                              onClick={() => {
                                handleChangeProductCondition();
                              }}
                            >
                              + Add another item
                            </Link>
                          </div>
                        </Card.Section>
                      </Card>
                      <Modal
                          open={activeConditionModal}
                          onClose={() => {
                            toggleConditionModal();
                            setCondition("");
                          }}
                          title={`Select ${condition}`}
                          primaryAction={{
                            content:
                              condition && !isEmpty(storeSelected)
                                ? "Save"
                                : "Close",
                            onAction: () => {
                              if (condition && !isEmpty(storeSelected)) {
                                let _obj = {
                                  type: condition,
                                  list: storeSelected.map((s) => ({
                                    shop_name: s,
                                    list: [],
                                  })),
                                };
                                selectedItems.map((item) => {
                                  set(
                                    _obj,
                                    "list" + item,
                                    getValue(global, condition + item)
                                  );
                                });
                                const _apType = getValue(
                                  applyCondition,
                                  "type",
                                  ""
                                );
                                const _type = getValue(_obj, "type", "");

                                if (
                                  typeof productConditionCurrentIndex ===
                                  "number"
                                ) {
                                  handleChangeProductCondition({
                                    name: "condition",
                                    value: _obj,
                                    idx: productConditionCurrentIndex,
                                  });
                                } else {
                                  if (_apType !== _type) {
                                    setApplyCondition(_obj);
                                  } else {
                                    let _list = getValue(_obj, "list", []);
                                    let mergeList = _list.map((o, i) => {
                                      let _arr = getValue(o, "list", []);
                                      const shop_name = getValue(
                                        o,
                                        "shop_name"
                                      );
                                      let willMergeList = getValue(
                                        applyCondition,
                                        "list",
                                        []
                                      ).find((o) => o.shop_name === shop_name)
                                        .list;
                                      let concatArr = _arr.concat(
                                        willMergeList
                                      );
                                      const uniqconcatArr = uniqBy(
                                        concatArr,
                                        "node.id"
                                      );
                                      set(o, "list", uniqconcatArr);
                                      return o;
                                    });
                                    setApplyCondition(_obj);
                                  }
                                }
                              }

                              toggleConditionModal();
                              handleChangeProductConditionCurrentIndex(false);
                            },
                          }}
                          onScrolledToBottom={() => {
                            fetchProducts(true);
                          }}
                        >
                          <Modal.Section>
                            {condition && !isEmpty(storeSelected) ? (
                              <div className="condition-list">
                                <ResourceList
                                  resourceName={{
                                    singular: condition,
                                    plural: condition,
                                  }}
                                  items={productListMakrup()}
                                  renderItem={renderItem}
                                  selectedItems={selectedItems}
                                  onSelectionChange={(value) => {
                                    setSelectedItems(value);
                                  }}
                                  selectable
                                  loading={resourceListLoading}
                                  filterControl={filterControl}
                                ></ResourceList>
                              </div>
                            ) : (
                              <div>Please select the stores.</div>
                            )}
                          </Modal.Section>
                        </Modal>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection title="Offer">
                      <Card>
                        <Card.Section title="">
                          <Checkbox 
                            label="Store discount based on the product price"
                            name="storeDiscountBasedOnProductPrice"
                            checked={storeDiscountBasedOnProductPrice}
                            onChange={handleChangeStoreDiscountBasedOnProductPrice}
                          />
                        </Card.Section>
                        <Card.Section title="Offer Items aggregator">
                          <ChoiceList
                            choices={[
                              { label: "One of the items below will be offered when the discount is triggered", value: "any" },
                              { label: "All of the items below will be offered when the discount is triggered", value: "all" },
                            ]}
                            selected={offerAggregator}
                            onChange={handleChangeOfferAggregator}
                          ></ChoiceList>
                        </Card.Section>
                        <Card.Section>
                          <TextField
                            name='max-quantity-discount'
                            type="number"
                            prefix="Quantity"
                            value={maxQuantityDiscount}
                            min="0"
                            onChange={handleChangeMaxQuantityDiscount}
                            helpText={'Leave 0 to apply unlimited'}
                            label="Max Qty Discount is Applied To"
                          />
                        </Card.Section>
                        <Card.Section title="Apply discount to these Offer Bundle Items Only">
                          <div className="stack__condition">
                            {offerItems &&
                              offerItems.map((condition, idx) => {
                                let list = getValue(
                                  condition,
                                  "condition.list",
                                  []
                                );
                                return (
                                  <>
                                    <Stack key={`offer_items-${idx}`}>
                                      <TextField
                                        name={`offer_items[${idx}][quantity]`}
                                        type="number"
                                        prefix="Quantity"
                                        value={getValue(condition, "quantity", "0")}
                                        min="1"
                                        onChange={(value) => {
                                          handleChangeOfferItems({
                                            name: "quantity",
                                            value,
                                            idx,
                                          });
                                        }}
                                      />
                                      <Button
                                        onClick={() => {
                                          setQueryValue("");
                                          setSelectedItems([]);
                                          setCondition("products");
                                          handleChangeOfferItemsCurrentIndex(
                                            idx
                                          );
                                          toggleModal();
                                        }}
                                      >
                                        Choose Products
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          setQueryValue("");
                                          setSelectedItems([]);
                                          handleChangeOfferItemsCurrentIndex(
                                            idx
                                          );
                                          setCondition("collections");
                                          toggleModal();
                                        }}
                                      >
                                        Choose Collections
                                      </Button>
                                      
                                      <Button
                                      onClick={() => {
                                        handleChangeOfferItems(idx);
                                      }}
                                      >
                                        <Icon source={DeleteMajorMonotone}/>
                                      </Button>
                                    </Stack>

                                    {!isEmpty(list) ? (
                                      <div className="InventoryTags">
                                        {list.map((item, ix) => {
                                          let shop_name = getValue(
                                            item,
                                            "shop_name",
                                            ""
                                          ).split(".")[0];
                                          const item_list = getValue(
                                            item,
                                            "list"
                                          );
                                          return (
                                            item_list &&
                                            item_list.map((_item, index) => {
                                              const title = getValue(
                                                _item,
                                                "node.title"
                                              );
                                              return title ? (
                                                <Tag
                                                  onRemove={() => {
                                                    let _condition = {
                                                      ...condition,
                                                    };
                                                    let _list = [...item_list];
                                                    _list.splice(index, 1);
                                                    set(
                                                      _condition,
                                                      `condition.list[${ix}][list]`,
                                                      _list
                                                    );
                                                    let _productCondition = [
                                                      ...productCondition,
                                                    ];
                                                    set(
                                                      _productCondition,
                                                      `[${idx}]`,
                                                      _condition
                                                    );
                                                    setOfferItems(
                                                      _productCondition
                                                    );
                                                  }}
                                                  key={shop_name + "-" + title}
                                                >
                                                  <strong>
                                                    {shop_name} :{" "}
                                                  </strong>
                                                  {title}
                                                </Tag>
                                              ) : null;
                                            })
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })}
                          </div>
                          <div className="bottom-action">
                            <Link
                              onClick={() => {
                                handleChangeOfferItems();
                              }}
                            >
                              + Add another item
                            </Link>
                          </div>
                        </Card.Section>
                        <Modal
                          open={activeModal}
                          onClose={() => {
                            toggleModal();
                            setCondition("");
                          }}
                          title={`Select ${condition}`}
                          primaryAction={{
                            content:
                              condition && !isEmpty(storeSelected)
                                ? "Save"
                                : "Close",
                            onAction: () => {
                              if (condition && !isEmpty(storeSelected)) {
                                let _obj = {
                                  type: condition,
                                  list: storeSelected.map((s) => ({
                                    shop_name: s,
                                    list: [],
                                  })),
                                };
                                selectedItems.map((item) => {
                                  set(
                                    _obj,
                                    "list" + item,
                                    getValue(global, condition + item)
                                  );
                                });
                                const _apType = getValue(
                                  applyCondition,
                                  "type",
                                  ""
                                );
                                const _type = getValue(_obj, "type", "");

                                if (
                                  typeof offerItemsCurrentIndex ===
                                  "number"
                                ) {
                                  handleChangeOfferItems({
                                    name: "condition",
                                    value: _obj,
                                    idx: offerItemsCurrentIndex,
                                  });
                                } else {
                                  if (_apType !== _type) {
                                    setApplyCondition(_obj);
                                  } else {
                                    let _list = getValue(_obj, "list", []);
                                    let mergeList = _list.map((o, i) => {
                                      let _arr = getValue(o, "list", []);
                                      const shop_name = getValue(
                                        o,
                                        "shop_name"
                                      );
                                      let willMergeList = getValue(
                                        applyCondition,
                                        "list",
                                        []
                                      ).find((o) => o.shop_name === shop_name)
                                        .list;
                                      let concatArr = _arr.concat(
                                        willMergeList
                                      );
                                      const uniqconcatArr = uniqBy(
                                        concatArr,
                                        "node.id"
                                      );
                                      set(o, "list", uniqconcatArr);
                                      return o;
                                    });
                                    setApplyCondition(_obj);
                                  }
                                }
                              }

                              toggleModal();
                              handleChangeOfferItemsCurrentIndex(false);
                            },
                          }}
                          onScrolledToBottom={() => {
                            fetchProducts(true);
                          }}
                        >
                          <Modal.Section>
                            {condition && !isEmpty(storeSelected) ? (
                              <div className="condition-list">
                                <ResourceList
                                  resourceName={{
                                    singular: condition,
                                    plural: condition,
                                  }}
                                  items={productListMakrup()}
                                  renderItem={renderItem}
                                  selectedItems={selectedItems}
                                  onSelectionChange={(value) => {
                                    setSelectedItems(value);
                                  }}
                                  selectable
                                  loading={resourceListLoading}
                                  filterControl={filterControl}
                                ></ResourceList>
                              </div>
                            ) : (
                              <div>Please select the stores.</div>
                            )}
                          </Modal.Section>
                        </Modal>
                      </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection title="Actions">
                      <Card>
                        <Card.Section>
                          <Checkbox
                            name="is_free_shipping"
                            checked={isFreeShipping}
                            label="Free Shipping"
                            onChange={handleChangeFreeShipping}
                          />
                        </Card.Section>
                        <Card.Section>
                          <div className="conected-row">
                            <div>
                              <Select
                                label="Apply"
                                name="apply"
                                options={applyListItem}
                                onChange={handleChangeApply}
                                value={selectedApply}
                              ></Select>
                            </div>
                            <div>
                              <TextField
                                label="Discount Amount"
                                type="text"
                                name="discount_amount"
                                value={discountAmout}
                                onChange={handleChangeDiscountAmout}
                                pattern="[+-]?([0-9]*[.])?[0-9]{0,1,2}"
                              ></TextField>
                              
                            </div>
                            <div>
                              <TextField
                                label="Discount Amount Limit"
                                type="text"
                                name="discount_amount_limit"
                                value={discountAmoutLimit}
                                placeholder="$"
                                pattern="[+-]?([0-9]*[.])?[0-9]{0,1,2}"
                                onChange={setDiscountAmoutLimit}
                              ></TextField>
                            </div>
                          </div>
                        </Card.Section>
                      </Card>
                    </Layout.AnnotatedSection>
                    {!isEmpty(getValue(editRule, "coupon_codes", [])) && PATH.discountCartDetail === path ? (
                      <div className="full-layout">
                        <Layout.AnnotatedSection>
                          <div style={{ padding: "0 0 15px 0" }}>
                            <Heading>Code Table</Heading>
                          </div>
                          <Card>
                            <CodesTable
                              data={getValue(editRule, "coupon_codes", [])}
                              ruleAction={ruleAction}
                              path={path}
                              cartRuleId={editRule.id}
                            />
                          </Card>
                        </Layout.AnnotatedSection>
                      </div>
                    ) : null}
                    <div
                      className="actions"
                      style={{ margin: "2rem 0 0 2rem" }}
                    >
                      {createDiscountError && (
                        <div style={{ marginBottom: "20px}" }}>
                          <InlineError message={createDiscountError} />
                        </div>
                      )}
                      <Button
                        primary
                        submit
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                )
              }
            </Formik>
          </Layout.Section>
        </Layout>
      </Page>
    </DefaultLayout>
  );
};

DiscountDetailCart.propTypes = {
  page: PropTypes.object,
  rule: PropTypes.object,
};
const mapActionToProps = ({ global, rule }) => ({
  globalAction: global,
  ruleAction: rule,
});
const mapStateToProps = ({ global, profile, rule, page }) => ({
  global,
  profile,
  rule,
  page,
});

export default connect(mapStateToProps, mapActionToProps)(DiscountDetailCart);

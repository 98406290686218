import { route } from '../store/hydrate';
import { STATUS_LOADING, STATUS_SUCCESS, PATH } from '../constants';

route(PATH.discountCreate, async ({ store }) => {
  
  const {
    dispatch: { page, global, rule }
  } = store;
  const _rule = store.getState().rule
  page.setStatus(STATUS_LOADING);
  try {
    rule.setState({..._rule, rules: []})
    await global.getStorage()
    page.setStatus(STATUS_SUCCESS);
  } catch (error) {
    page.setErrors(error);
  }
});

import React, { useEffect, memo } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import qs from "qs";
import hydrate, { init } from "../store/hydrate";
import routes from "../routes";
import "../controllers";
import { PATH} from "../constants";
import "../layout";
import _ from "lodash";
const Layout = ({ store, params }) => {
  const state = store.getState();
  const history = useHistory();
  const {
    location: { pathname, search },
  } = params;

  useEffect(() => {
    const query = search.length > 0 ? qs.parse(search.slice(1)) : {};
    const request = {
      path: pathname,
      query,
      url: `${pathname}${search}`,
    };
    init(request, store).then(() => {
      hydrate(request, store);
    });
  }, [pathname, search]);

  const loggedIn = _.get(
    "loggedIn",
    state.axios.config,
    Boolean(localStorage.getItem("accessToken"))
  );
  return (
    <div>
      <Switch>
        {routes.map(({ component: Component, ...routeProps }) => {
          return (
            <Route
              {...routeProps}
              key={routeProps.path}
              render={() => {
                const {
                  path,
                  meta: { requiredAuth },
                } = routeProps;
                if (requiredAuth && !loggedIn) {
                  return <Redirect key={path} to={PATH.login} />;
                }
                if (path === PATH.login && loggedIn) {
                  return <Redirect key={path} to={PATH.home} />;
                }
                if (path === PATH.logout) {
                  store.dispatch.profile
                    .logout()
                    .then((rs) => history.push(PATH.login));
                }
                return <Component {...routeProps} />;
              }}
            />
          );
        })}
        {routes.map((routeProps) => {
          return <Route {...routeProps} key={routeProps.path} />;
        })}
      </Switch>
    </div>
  );
};

Layout.propTypes = {
  store: PropTypes.object,
  params: PropTypes.object,
};

export default memo(Layout, (props, nextProps) => {
  const {
    params: { location: prevLocation },
  } = props;
  const {
    params: { location: nextLocation },
  } = nextProps;
  return (
    prevLocation.pathname === nextLocation.pathname &&
    prevLocation.search === nextLocation.search
  );
});

const env = {
  state: {},
  reducers: {
    setState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    }
  },
};

export default env;

import { post, get, put, deleteReq } from '../../utils/http';
import { setStorage } from '../../utils/helpers';
import {BASE_URL} from '../../constants'
import {extractError} from '../../utils/helpers'
const profile = {
  state: {
    user: {},
    userDetail: {}
  },
  reducers: {
    setCurrent(state, payload) {
      return {
        ...state,
        current: payload
      };
    },
    updateUser(state, payload) {
      return {
        ...state,
        user: payload
      };
    },
    setUserDetail(state, payload) {
      return {
        ...state,
        userDetail: payload
      };
    },
    setState(state, payload) {
      return {
        ...state,
        ...payload
      };
    }
  },
  effects: dispatch => ({
    async register(payload = {}, rootState) {
      try {
        const { email, username, password, stores } = payload;
        await post(`${BASE_URL}/auth/local/register`, {
          username,
          email,
          password,
          stores
        });
        return '';
      } catch (error) {
        return extractError(error)
      }
    },
    async login(payload, rootState) {
      try {
        const data = await post(
          `${BASE_URL}/auth/local`, {
            identifier: payload.username,
            password: payload.password
          }
        )
        // add
        if(data) {
          dispatch.axios.setToken(data.jwt);
          dispatch.profile.setState({ user: data.user });
          setStorage('accessToken', data.jwt)
          return '';
        }
      } catch (error) {
        return extractError(error)
      }
    },
    async logout() {
      dispatch.axios.setToken('');
      setStorage('accessToken', '')
    },
    async getUserInfo(payload, rootState) {
      const { config } = rootState.axios;
      const { data } = rootState.user;
      try {
        const me = await get(`${BASE_URL}/users/me`, config)
        dispatch.profile.setState({...data, user: me});
      } catch(error) {
        return extractError(error)
      }
    },
    async fetchUser(id, rootState) {
      try {
        const { config } = rootState.axios;
        const userDetail = await get(`${BASE_URL}/users/${id}`, config)
        dispatch.profile.setUserDetail(userDetail)
      } catch(error) {
        return extractError(error)
      }
    
    },
    async updateUserInfo(payload, rootState) {
      try {
        const {id, values} = payload;
        const { config } = rootState.axios;
        const userDetail = await put(`${BASE_URL}/users/${id}`, values, config)
        dispatch.profile.setUserDetail(userDetail)
      } catch(error) {
        return extractError(error)
      }
    },
    async deleteUser(payload, rootState) {
      try {
        const {id} = payload;
        const { config } = rootState.axios;
        await deleteReq(`${BASE_URL}/users/${id}`, config)
        dispatch.profile.setUserDetail({})
      } catch(error) {
        return extractError(error)
      }
    }
  })
};

export default profile;

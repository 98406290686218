import PropTypes from "prop-types";
import React, {useState, useCallback} from "react";
import { isEmpty, get } from "lodash";
import moment from "moment";
import {convertText} from "../../../utils/helpers"
import {
  Scrollable,
  Badge,
  DataTable,
  Tooltip,
  Button,
  Icon,
  Modal,
  TextContainer
} from "@shopify/polaris";
import { DeleteMajorMonotone, ExportMinor } from "@shopify/polaris-icons";
import { CSVLink } from "react-csv";
const CodesTable = ({ data, ruleAction, path, profile, cartRuleId }) => {
  const tableHeader = [
    "Code",
    "Uses Per Code",
    "Created at",
    "Status",
    "Number of Uses",
    "Order Name",
    "",
  ];
  let rulesCSV = []
  const filename = `Export-${moment().format('YYYY-MM-DD')}.csv`
  let csvHeader = tableHeader.map(item => {
    if(item) {
      return {
        label: item,
        key: convertText(String(item))
      }
    }
  })
  csvHeader = csvHeader.filter(o => !isEmpty(o))
  const [deleteId, setdeleteId] = useState();
  const handleChangeDeleteID = useCallback((value) => setdeleteId(value), []);
  const [activeDelete, setactiveDelete] = useState();
  const handleChangeDelete = useCallback(() => setactiveDelete(!activeDelete), [
    activeDelete,
  ]);
  
  const [isLoading, setLoading] = useState();
  const handleChangeLoading = useCallback(() => setLoading(!isLoading), [
    isLoading,
  ]);
  const deleteCode = async () => {
    handleChangeLoading();
    let _data = await ruleAction.deleteCode({ id: deleteId, path });
    await ruleAction.fetchCartRuleEdit({query:`id=${cartRuleId}`})
    setLoading(false);
    handleChangeDelete();
  };
  const _rows = data.map((item) => {
    const {
      code,
      uses_per_code,
      is_available,
      number_of_uses,
      orders_name,
      created_at,
      id
    } = item;
    rulesCSV.push({
      code,
      uses_per_code:  uses_per_code || 0,
      status: is_available && number_of_uses < uses_per_code
          ? "Available"
          : "Unavailable",
      created_at,
      number_of_uses,
      order_name: orders_name && orders_name.map(name => name ? name + ',' : ''),
    })
    return [
      code,
      uses_per_code || 0,
      moment(created_at).format("YYYY-MM-DD"),
      <Badge
        status={
          is_available && number_of_uses < uses_per_code
            ? "success"
            : ""
        }
      >
        {is_available && number_of_uses < uses_per_code
          ? "Available"
          : "Unavailable"}
      </Badge>,
      number_of_uses || 0,
      !isEmpty(orders_name)
        ? orders_name.map((name) => <p key={name}>{name}</p>)
        : "",
      <Tooltip content="Delete">
        <Button
          onClick={() => {
            handleChangeDelete();
            handleChangeDeleteID(id);
          }}
        >
          <Icon source={DeleteMajorMonotone} />
        </Button>
      </Tooltip>,
    ];
  })
  return (
    <>
        {rulesCSV && (
          <div className="Polaris-Header-Title__SubTitle custom export-link">
            <CSVLink data={rulesCSV} headers={csvHeader} filename={filename}>
              <span className="Polaris-Navigation__Text">Export</span>
              <Icon source={ExportMinor} />
            </CSVLink>
          </div>
        )}
      <Scrollable
        shadow
        style={{
          height:
            data.length < 5 ? data.length * 57 + 53 + "px" : 5 * 57 + 53 + "px",
        }}
      >
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
          ]}
          headings={tableHeader}
          rows={_rows}
        />
      </Scrollable>
      <Modal
        open={activeDelete}
        onClose={handleChangeDelete}
        secondaryActions={{
          loading: isLoading,
          content: "Cancel",
          onAction: handleChangeDelete,
        }}
        primaryAction={[
          {
            loading: isLoading,
            content: "Confirm",
            onAction: deleteCode,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};
CodesTable.propTypes = {
  data: PropTypes.array,
};
export default CodesTable;

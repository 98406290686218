import get from "lodash.get";
import moment from "moment";
export const isObject = (data) =>
  Object.prototype.toString.call(data) === "[object Object]";
export const isString = (data) =>
  Object.prototype.toString.call(data) === "[object String]";
export const isArray = (data) =>
  Object.prototype.toString.call(data) === "[object Array]";
export const setStorage = (cname, cvalue) => {
  localStorage.setItem(cname, cvalue);
};
export const extractError = (data) =>
  get(data, "response.statusText", "Error!");
export const  sortByDate = (a, b) => new Date(b.created_at) - new Date(a.created_at);

export const convertText = str => String(str).toLowerCase().replaceAll(' ', '_')
export const moneyFormat = (cents, format) => {
  if (typeof cents == "string") {
    cents = cents.replace(".", "");
  }
  var value = "";
  var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  var formatString = format;

  function defaultOption(opt, def) {
    return typeof opt == "undefined" ? def : opt;
  }

  function formatWithDelimiters(number, precision, thousands, decimal) {
    precision = defaultOption(precision, 2);
    thousands = defaultOption(thousands, ",");
    decimal = defaultOption(decimal, ".");

    if (isNaN(number) || number == null) {
      return 0;
    }

    number = (number / 100.0).toFixed(precision);

    var parts = number.split("."),
      dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + thousands),
      cents = parts[1] ? decimal + parts[1] : "";

    return dollars + cents;
  }

  switch (formatString.match(placeholderRegex)[1]) {
    case "amount":
      value = formatWithDelimiters(cents, 2);
      break;
    case "amount_no_decimals":
      value = formatWithDelimiters(cents, 0);
      break;
    case "amount_with_comma_separator":
      value = formatWithDelimiters(cents, 2, ".", ",");
      break;
    case "amount_no_decimals_with_comma_separator":
      value = formatWithDelimiters(cents, 0, ".", ",");
      break;
  }

  return formatString.replace(placeholderRegex, value);
};

export const queryCollection = (shop, filter) => {
  const _obj = filter
  ? { args: { first: 200, query: filter } }
  : { args: { first: 200 } };
  shop.addConnection("collections",_obj, (collection) => {
    collection.add("id");
    collection.add("title");
    collection.add("image", { args: {} }, (images) => {
      images.add("src");
    });
  });
};
export const queryProduct = (shop, filter) => {
  const _obj = filter
    ? { args: { first: 10, query: filter } }
    : { args: { first: 10 } };
  shop.addConnection(
    "products",
    _obj,
    (product) => {
      product.add("title");
      product.add("id");
      product.add("handle");
      product.addConnection("variants", { args: { first: 10 } }, (v) => {
        v.add("title");

        v.add("id");
        v.add("price");
        v.add("image", { args: {} }, (images) => {
          images.add("src");
        });
      });
      product.addConnection("images", { args: { first: 10 } }, (images) => {
        images.add("src");
      });
    }
  );
};
export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) 
import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import {
  InlineError
} from "@shopify/polaris"; 
const CustomErrorMessage = ({ name }) => (
  <ErrorMessage name={name}>
    {msg => <InlineError message={msg}></InlineError>}
  </ErrorMessage>
);

CustomErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CustomErrorMessage;

import { preRouteSync } from '../../store/hydrate';
import {setStorage} from '../../utils/helpers'
preRouteSync(async ({ store }) => {
  // const history = useHistory();
  const { dispatch: { profile, axios } } = store;
  const { axios: { config } } = store.getState();
  try {
    if (config.accessToken) {
      const error = await profile.getUserInfo();
      if(error) {
        axios.setConfig({...config, loggedIn: false})
        setStorage('accessToken', '')
        window.location.href="/login"
      }
    }
  } catch (error) {
    console.log('auth:error', error);
  }
});

import React from "react";
import { Field } from "formik";
import ErrorMessage from "./CustomErrorMessage";
const FieldComp = ({labelText, tpye, ...props}) => {
  return (
    <div className="">
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label>{labelText}</label>
        </div>
      </div>
      <div className="Polaris-Connected">
        <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
          <div className="Polaris-TextField Polaris-TextField--hasValue">
            <Field className="Polaris-TextField__Input" {...props}/>
            <div className="Polaris-TextField__Backdrop"></div>
          </div>
        </div>
      </div>
      <ErrorMessage name={props.name}></ErrorMessage>
    </div>
  );
};
export default FieldComp;

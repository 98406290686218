import { PATH } from "./constants";
import Register from "./components/pages/Register";
import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import Discount from "./components/pages/Discount";
import DiscountCart from "./components/pages/DiscountCart";
import DiscountDetail from "./components/pages/DiscountDetail";
import DiscountDetailCart from "./components/pages/DiscountDetailCart";
import User from "./components/pages/User";
import UserDetail from "./components/pages/UserDetail";

const routes = [
  {
    path: PATH.home,
    exact: true,
    component: Home,
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: PATH.register,
    exact: true,
    component: Register,
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: PATH.login,
    exact: true,
    component: Login,
    meta: {
      requiredAuth: false,
    },
  },
  {
    path: PATH.logout,
    exact: true,
    component: Login,
    meta: {
      requiredAuth: false,
    },
  },
  // {
  //   path: PATH.discount,
  //   exact: true,
  //   component: Discount,
  //   meta: {
  //     requiredAuth: true,
  //   },
  // },
  {
    path: PATH.discountCart,
    exact: true,
    component: DiscountCart,
    meta: {
      requiredAuth: true,
    },
  },
  // {
  //   path: PATH.discountDetail,
  //   exact: true,
  //   component: DiscountDetail,
  //   meta: {
  //     requiredAuth: true,
  //   },
  // },
  {
    path: PATH.discountCreate,
    exact: true,
    component: DiscountDetail,
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: PATH.discountCreateCart,
    exact: true,
    component: DiscountDetailCart,
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: PATH.discountCartDetail,
    exact: true,
    component: DiscountDetailCart,
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: PATH.user,
    exact: true,
    component: User,
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: PATH.userDetail,
    exact: true,
    component: UserDetail,
    meta: {
      requiredAuth: true,
    },
  },
];

export default routes;

import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DefaultLayout from "../../layouts/DefaultLayout";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import {PATH} from "../../../constants";
import {moneyFormat, sortByDate} from '../../../utils/helpers'
import * as getValue from "lodash.get";
import {uniq, get} from "lodash";
import {
  Layout,
  Page,
  Tabs,
  Card,
  DataTable,
  Badge,
  Avatar,
  Icon,
  Modal,
  ButtonGroup,
  Button,
  TextContainer,
  Tooltip,
  Filters,
  ChoiceList,
  Pagination
} from "@shopify/polaris";
import {
  PlayMajorMonotone,
  DeleteMajorMonotone,
  EditMajorMonotone,
  PauseMajorMonotone
} from "@shopify/polaris-icons";

const DiscountCart = ({ page, profile, rule, global, ruleAction, path }) => {
  const itemPerPage = 5;
  const { status } = page;
  const history = useHistory();
  const [rows, setrows] = useState([]);
  const [pageIndex, setpageIndex] = useState(0);
  const [filteredRows, setfilteredRows] = useState(null);
  const [hasPrevious, sethasPrevious] = useState(false);
  const [hasNext, sethasNext] = useState(true);
  const [sortedRows, setSortedRows] = useState(null);
  const [activeDelete, setactiveDelete] = useState();
  const [active, setActive] = useState();
  const [deleteId, setdeleteId] = useState();
  const [activeId, setactiveId] = useState();
  const [isLoading, setLoading] = useState();

  const handleChangeDelete = useCallback(() => setactiveDelete(!activeDelete), [
    activeDelete,
  ]);
  const handleChangeActive = useCallback(() => setActive(!active), [active]);
  const handleChangeLoading = useCallback(() => setLoading(!isLoading), [
    isLoading,
  ]);
  const handleChangeDeleteID = useCallback((value) => setdeleteId(value), []);
  const handleChangeActiveID = useCallback((value) => setactiveId(value), []);
  const deleteRule = async () => {
    const {
      user: { id },
    } = profile;
    handleChangeLoading();
    await ruleAction.deleteCartRule({ id: deleteId, path });
    await ruleAction.fetchCartRule({ query: `user.id=${id}` });
    setLoading(false);
    handleChangeDelete();
  };

  const activeRule = async () => {
    const {
      user: { id },
    } = profile;
    handleChangeLoading();

    const { cartRules } = rule;
    const willlUpdateActiveRule = cartRules.find(o => o.id === activeId)
    const willUpdateStatus = getValue(willlUpdateActiveRule, 'status', false)
    await ruleAction.updateCartRule({ id: activeId, values: { status : !willUpdateStatus} });
    await ruleAction.fetchCartRule({ query: `user.id=${id}` });
    setLoading();
    handleChangeActive();
  };
  const { cartRules } = rule;
  const priorityList = uniq(cartRules.map(r => r.priority));

  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      history.push(tabs[selectedTabIndex].path)
      // setSelected(selectedTabIndex)
    },
    []
  );
  function sortTable(rows, index, direction) {
    let result = [];
    switch(index) {
      case 5:
      case 6:
      case 7:
        result = [...rows].sort((a, b) => {
          return _sortByDate(a, b, index, direction)
        })
        break;
      case 2:
        result = [...rows].sort((a, b) => {
          return sortByNumber(a, b, index, direction)
        })
        break;
    }
    return result;
  }
  function _sortByDate(rowA, rowB, index, direction) {
    const dateA = moment(rowA[index]);
    const dateB = moment(rowB[index]);
    
    return direction === 'descending' ? dateB.diff(dateA) : dateA.diff(dateB)
  }
  const [availability, setAvailability] = useState(null);
  const [productType, setProductType] = useState(null);
  const [taggedWith, setTaggedWith] = useState(null);
  const [queryValue, setQueryValue] = useState(null);

  const handleAvailabilityChange = useCallback(
    (value) => setAvailability(value),
    [],
  );
  const handleProductTypeChange = useCallback(
    (value) => setProductType(value),
    [],
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    [],
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );
  const handleAvailabilityRemove = useCallback(() => setAvailability(null), []);
  const handleProductTypeRemove = useCallback(() => setProductType(null), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleProductTypeRemove();
    handleTaggedWithRemove();
  }, [
    handleAvailabilityRemove,
    handleQueryValueRemove,
    handleProductTypeRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: 'store',
      label: 'Storage',
      filter: (
        <ChoiceList
          title="Store"
          titleHidden
          choices={global.storage.map(store => {
            return {
              label: store.name,
              value: store.name
            }
          })}
          selected={availability || []}
          onChange={(value) => {
            handleAvailabilityChange(value);
            // filterRows();
          }}
          allowMultiple
        />
      )
    },
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          title="Store"
          titleHidden
          choices={[
            {label: 'Active', value: 'active'},
            {label: 'Deactive', value: 'deactive'},
            {label: 'Expired', value: 'expired'}
          ]}
          selected={productType || []}
          onChange={(value) => {
            handleProductTypeChange(value);
          }}
          
        />
      )
    },
    {
      key: 'priority',
      label: 'Priority',
      filter: (
        <ChoiceList
          title="Priority"
          titleHidden
          choices={priorityList.map(p => {
            return {
              label: p,
              value: p
            }
          })}
          selected={taggedWith || []}
          onChange={(value) => {
            handleTaggedWithChange(value);
          }}
          allowMultiple
        />
      )
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = 'store';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }
  if (!isEmpty(productType)) {
    const key = 'status';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, productType),
      onRemove: handleProductTypeRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = 'priority';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  function sortByNumber(rowA, rowB, index, direction) {
    const numberA = rowA[index];
    const numberB = rowB[index];
    
    return direction === 'descending' ? numberB - numberA : numberA - numberB;
  }
  const handleSort = useCallback(
    (index, direction) => setSortedRows(sortTable(rows, index, direction)),
    [rows],
  );
  const tabs = [
    // {
    //   id: "catalog-rule",
    //   content: "Catalog Price Rule",
    //   path: PATH.discount
    // },
    {
      id: "cart-rule",
      content: "Cart Price Rule",
      path: PATH.discountCart
    },
  ];
  const onNext = () => {
    let _pageIndex = pageIndex;
    const localPageIndex = pageIndex + itemPerPage;
    if(_pageIndex == 0) {
      _pageIndex = itemPerPage * 2;
    }
    let localRows = filteredRows || sortedRows || rows;
    let maxPageIndex = localRows.length + 1;
    if(localPageIndex <= maxPageIndex) {
      setpageIndex(localPageIndex);
    } 
    if(_pageIndex <= maxPageIndex) {
      sethasNext(true);
    } else {
      sethasNext(false);
    }
    sethasPrevious(true);
  }

  const onPrev = () => {
    let _pageIndex = pageIndex - itemPerPage;
    if(_pageIndex <= itemPerPage) {
      _pageIndex = 0;
      sethasPrevious(false);
    } else {
      sethasPrevious(true);
    }
    setpageIndex(_pageIndex);
    sethasNext(true);
  }

  const paginationMarkup = () => {
    return <Pagination
            hasPrevious={hasPrevious}
            onPrevious={() => {
              onPrev()
              filterRowsMakrup();
            }}
            hasNext={hasNext}
            onNext={() => {
              onNext();
              filterRowsMakrup();
            }}
          />
  }
  useEffect(() => {
    const localRows = sortedRows || rows;
    let result = localRows.filter((row, index) => {
      let isAvaibility = true;
      let isproductType = true;
      let isTaggedWith = true;
      let isMatchRuleName = true;
      const _id = row.slice(-1).pop();
      const _rule = rule.cartRules.find(r => r.id == _id);
      const _stores = _rule.stores.map(s => s.name);
      if(!(!availability || isEmpty(availability))) {
        isAvaibility = availability.every(a => _stores.includes(a))
      }
      if(!(!productType || isEmpty(productType))) {
        const rowStatus = get(row, '[1].props.children', '');
        isproductType = productType.every(p => {
          return rowStatus.toLowerCase() == p
        });
      }
      if(!(!taggedWith || isEmpty(taggedWith))) {
        isTaggedWith = taggedWith.some(t => _rule.priority === t);
      }
      if(queryValue) {
        isMatchRuleName = String(_rule.name).toLowerCase().includes(String(queryValue).toLowerCase());
      }
      return isAvaibility && isproductType && isTaggedWith && isMatchRuleName;
    });
    setfilteredRows(result);
    setpageIndex(0);
  }, [availability, productType, taggedWith, queryValue, sortedRows, rows]);
  useEffect(() => {
      let _rows = [...cartRules].sort(sortByDate).map((r) => {
        const { id, name, status, count, from, to, total_amount, toTime, fromTime, timezone, created_at, priority, stores } = r;
        const _endT = moment(toTime, "HH:mm");
        const _fromT = moment(fromTime, "HH:mm");
        const _to = moment(to).tz(timezone || "etc/UTC").set({
          hours: _endT.hours(),
          minutes: _endT.minutes(),
        });
        const _from = moment(from).tz(timezone || "etc/UTC").set({
          hours: _fromT.hours(),
          minutes: _fromT.minutes(),
        });
        const _today = moment().tz(timezone || "etc/UTC");
        return [
          name,
          <Badge status={status ? (!_to.isBefore(_today)? "success" : "") : ""}>
            {
              _to.isBefore(_today) ? 'Expired' :
            (status ? "Active" : "Deactive")
            }
          </Badge>,
  
          priority,
          `${count || 0} used`,
          `${moneyFormat(total_amount*100, '${{amount}}')}`, //Fixed shopify default rounding -> 100
          _from.format('YYYY/MM/DD'),
          _to.format('YYYY/MM/DD'),
          moment(created_at).format('YYYY/MM/DD'),
  
          <ButtonGroup segmented>
            <Tooltip content="Edit">
  
              <Button>
                <Link to={`/cartdiscounts/${id}`} title="Edit">
                  <Icon source={EditMajorMonotone} />
                </Link>
              </Button>
            </Tooltip>
            <Tooltip content="Delete">
            <Button
              onClick={() => {
                handleChangeDelete();
                handleChangeDeleteID(id);
              }}
            >
              <Icon source={DeleteMajorMonotone} />
            </Button>
            </Tooltip>
            {!status ? (
              <Tooltip content="Active">
                <Button
                  onClick={() => {
                    handleChangeActive();
                    handleChangeActiveID(id);
                  }}
                >
                  <Icon source={PlayMajorMonotone} />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip content="Pause">
                <Button
                  onClick={() => {
                    handleChangeActive();
                    handleChangeActiveID(id);
                  }}
                >
                  <Icon source={PauseMajorMonotone} />
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>,
          id
        ];
      });
      setrows(_rows);
    
  }, [cartRules])
  const filterRowsMakrup = () => {
    const localRows = filteredRows || sortedRows || rows;
    return localRows.slice(pageIndex, pageIndex + itemPerPage);
  }
  return (
    <DefaultLayout pageStatus={status}>
      <Page
        title="Discount"
        primaryAction={{
          content: "Create Discount",
          url: PATH.discountCreateCart,
        }}
        additionalNavigation={<Avatar size="small" customer />}
      >
        <Layout>
          <Layout.Section>
            <Card>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <Card.Section>
                  <Filters
                    queryValue={queryValue}
                    filters={filters}
                    appliedFilters={appliedFilters}
                    onQueryChange={handleFiltersQueryChange}
                    onQueryClear={handleQueryValueRemove}
                    onClearAll={handleFiltersClearAll}
                    queryPlaceholder="Search rule by name"
                  />
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                    ]}
                    sortable={[false, false, true, false, false, true, true, true]}
                    defaultSortDirection="descending"
                    onSort={handleSort}
                    initialSortColumnIndex={7}
                    headings={["Name", "Status", "Priority", "Count", "Total Amount", "Start Date", "End Date", "Created At", ""]}
                    rows={ filterRowsMakrup() }
                  />
                  {
                    paginationMarkup()
                  }
                  
                </Card.Section>
              </Tabs>
            </Card>
          </Layout.Section>
        </Layout>
        <Modal
          open={activeDelete}
          onClose={handleChangeDelete}
          secondaryActions={{
            loading: isLoading,
            content: "Cancel",
            onAction: handleChangeDelete,
          }}
          primaryAction={[
            {
              loading: isLoading,
              content: "Confirm",
              onAction: deleteRule,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>Are you sure?</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
        <Modal
          open={active}
          onClose={handleChangeActive}
          secondaryActions={{
            loading: isLoading,
            content: "Cancel",
            onAction: handleChangeActive,
          }}
          primaryAction={[
            {
              loading: isLoading,
              content: "Confirm",
              onAction: activeRule,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>Are you sure?</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </Page>
    </DefaultLayout>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case 'priority':
        return `Priority: ${value}`;
      case 'store':
        return value.map((val) => `Available on ${val}`).join(', ');
      case 'status':
        return `Status: ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
};

DiscountCart.propTypes = {
  page: PropTypes.object,
};

const mapStateToProps = ({ page, profile, rule, global }) => ({
  page,
  profile,
  rule,
  global
});

const mapActionToProps = ({ rule }) => ({
  ruleAction: rule,
});

export default connect(mapStateToProps, mapActionToProps)(DiscountCart);

import { get } from '../../utils/http';
import { STATUS_INITIAL } from '../../constants';

const user = {
  state: {
    status: STATUS_INITIAL,
    data: {}
  },
  reducers: {
    setData(state, payload) {
      return {
        ...state,
        data: payload
      };
    }
  },
  effects: dispatch => ({
    async getUser(payload, rootState) {
      const { config } = rootState.axios;
      const { data } = rootState.user;
      try {
        const userList = await get(
          `${config.backendUrl}/users`,
          config
        );
        dispatch.user.setData({ ...data, userList });
      } catch (error) {
        dispatch.page.setErrors(error);
      }
    }
  })
};

export default user;

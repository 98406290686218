/* eslint-disable no-await-in-loop */
import Route from 'route-parser';

const controllers = [];
const pre = [];
const preSync = [];

export async function init(request, store) {
  for (let i = 0; i < preSync.length; i++) {
    const f = preSync[i];
    await f(request, store);
  }
  await Promise.all(pre.map(f => {
    return f(request, store);
  }));
}

export default async function hydrate(request, store) {
  await Promise.all(controllers.map(f => {
    return f(request, store);
  }));
}

export function route(path, cb, options = {}) {

  controllers.push((request, store) => {
    if (path === '*') {
      return cb({ request, route: { path, params: {} }, store });
    }
    const r = new Route(path);
    const params = r.match(request.path);
    if (params !== false) {
      return cb({ request, route: { path, params }, store });
    }
    return null;
  });
}


export function preRoute(cb, options = {}) {
  const defaultOptions = {
    node: true,
    browser: false,
  };
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };
  const isBrowser = process.env.IS_BROWSER;
  if (!mergedOptions.browser && isBrowser) {
    return;
  }
  const isNode = !isBrowser;
  if (!mergedOptions.node && isNode) {
    return;
  }
  pre.push((request, store) => {
    return cb({ request, store });
  });
}

export function preRouteSync(cb, options = {}) {
  const defaultOptions = {
    node: true,
    browser: false,
  };
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };
  const isBrowser = process.env.IS_BROWSER;
  if (!mergedOptions.browser && isBrowser) {
    return;
  }
  const isNode = !isBrowser;
  if (!mergedOptions.node && isNode) {
    return;
  }
  preSync.push((request, store) => {
    return cb({ request, store });
  });
}

import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Route, BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout.jsx";
import ScrollToTop from "./components/ScrollToTop.jsx";

const App = ({ store, context = {}, request = {}, client }) => {
  const Router = BrowserRouter;
  const routerProps = { context, location: request.path };
  return (
    <Provider store={store}>
      <Router {...routerProps}>
        <ScrollToTop />
        <Route
          path="/"
          render={(params) => {
            return <Layout store={store} params={params} client={client} />;
          }}
        />
      </Router>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
  context: PropTypes.object,
  request: PropTypes.object,
};

export default App;

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { registerSchema } from "../../../utils/formSchema";
import DefaultLayout from "../../layouts/DefaultLayout";
import { connect } from "react-redux";
import FieldComp from "../../Form/Field";
import { get } from "lodash";
import {
  Layout,
  Page,
  Card,
  FormLayout,
  OptionList,
  Toast,
  Button,
  Avatar,
  InlineError,
} from "@shopify/polaris";

const Register = ({ profileAction, global }) => {
  const storage = get(global, "storage", []);
  const optionsList = storage.map(s => ({label: s.name, value: s.id}))
  const [registerSuccessfully, setregisterSuccessfully] = useState(false);
  const [registerError, setRegisterError] = useState("");
  const [storeSelected, setStoreSelected] = useState([]);
  const initialRegisterValues = {
    username: "",
    password: "",
    passwordConfirm: "",
    email: "",
  };
  const handleStoreChange = useCallback((value) => setStoreSelected(value), []);
  const toggleToast = useCallback(
    () => setregisterSuccessfully((active) => !active),
    []
  );
  const toastMarkup = registerSuccessfully ? (
    <Toast
      content="Register Successfully"
      onDismiss={toggleToast}
      duration={2000}
    />
  ) : null;
  const storeListMarkup = optionsList.length ? (
    <Card>
      <OptionList
        allowMultiple
        title="Stores"
        options={optionsList}
        onChange={handleStoreChange}
        selected={storeSelected}
      />
    </Card>
  ) : null;
  return (
    <DefaultLayout>
      <Page
        title="Creating User"
        additionalNavigation={
          <Avatar size="small" initials="AM" customer={false} />
        }
      >
        {toastMarkup}
        <Layout>
          <Layout.Section>
            <Formik
              initialValues={initialRegisterValues}
              validationSchema={registerSchema}
              onSubmit={async (values, actions) => {
                values.stores = storeSelected.map(s => ({id: s}));
                const error = await profileAction.register(values);
                if (error) {
                  setRegisterError(registerError);
                } else {
                  setregisterSuccessfully(true);
                }
              }}
            >
              {({ isValid, isSubmitting }) => (
                <Form>
                  <Card sectioned>
                    <FormLayout>
                      {registerError && <InlineError message={registerError} />}
                      <FieldComp labelText="Username" name="username" />
                      <FieldComp
                        labelText="Password"
                        name="password"
                        type="password"
                      />
                      <FieldComp
                        labelText="Confirm Password"
                        name="passwordConfirm"
                        type="password"
                      />
                      <FieldComp labelText="Email" name="email" type="email" />
                      {storeListMarkup}

                      <div className="actions">
                        <Button
                          primary
                          submit
                          disabled={!isValid || isSubmitting}
                          loading={isSubmitting}
                        >
                          Save
                        </Button>
                      </div>
                    </FormLayout>
                  </Card>
                </Form>
              )}
            </Formik>
          </Layout.Section>
        </Layout>
      </Page>
    </DefaultLayout>
  );
};
Register.propTypes = {
  profileAction: PropTypes.object,
};

const mapStateToProps = ({ global }) => ({ global });
const mapActionToProps = ({ profile }) => ({
  profileAction: profile,
});
export default connect(mapStateToProps, mapActionToProps)(Register);
